@import url("https://fonts.googleapis.com/css?family=Poppins:300,300i,400,500,500i,600,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,300i,400,500,500i,600,700,700i&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100%;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}
.overflow-y {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
/* Firefox */
.overflow-y::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
.error-message {
  color: red;
  font-size: 14px;
}
*,
p,
h1,
h2,
h3,
h4,
h5,
h6,
button,
a {
  line-height: 1;
}
.row {
  padding: 0 !important;
  margin: 0 !important;
}
main {
  margin-top: 74px;
  padding: 0 !important;
}
.image-preview {
  border: 2px solid #f1f1f1;
  margin: 30px auto 0;
  width: 300px;
  height: 300px;
}
.image-preview span {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    display: block;
    text-align: center;
  }
.image-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
.modal--content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
}
.modal--content .modal-remove-wrapper {
    text-align: right;
  }
.modal--content .modal-remove-wrapper .remove-modal-button {
      font-size: 22px;
      cursor: pointer;
      font-weight: 700;
    }
.modal--content .modal--head {
    padding: 30px 5px 0 5px;
  }
@media (max-width: 768px) {
.modal--content .modal--head {
      padding: 30px 5px 5px 5px
  }
    }
.modal--content .modal--head .modal--title {
      font-weight: 600;
    }
@media (max-width: 768px) {
.modal--content .modal--head .modal--title {
        font-size: 26px
    }
      }
.modal--content .modal--head .modal--description {
      margin-bottom: 5px;
    }
.response--error {
  margin-top: 15px;
  padding: 0 5px 0;
}
.response--error div {
    background-color: red;
    padding: 5px 15px;
    border-radius: 8px;
  }
.response--error div p {
      margin: 0;
      color: #ffffff;
    }
.response--success {
  margin-top: 15px;
  padding: 0 5px 0;
}
.response--success div {
    background-color: green;
    padding: 5px 15px;
    border-radius: 8px;
  }
.response--success div p {
      margin: 0;
      color: #ffffff;
    }
.parent {
  border: 1px solid #000000;
}
.form--data {
  padding-top: 15px;
  padding-bottom: 15px;
}
.form--data .form--label {
    padding: 15px 15px 0 10px;
    font-weight: 700;
  }
.form--data .form--group {
    display: none;
    padding: 6px 5px;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: auto;
    position: relative;
  }
.form--data .form--group .form--control {
      width: 100%;
      padding: 10px 15px;
      border-radius: 8px;
      outline: none;
      font-weight: 600;
      transition: border 0.15s forwards;
      border: 2px solid #dfdfdf;
    }
.form--data .form--group .form--control:focus {
        border-color: #000000;
      }
.form--data .form--group .form--control::placeholder {
        color: #8c8c8c;
        font-weight: 600;
      }
.form--data .error-text {
    color: red;
    font-weight: 500;
  }
.form--data .submit--group {
    padding: 15px 0;
  }
.notification--alert {
  position: absolute;
  background-color: red;
  color: #ffffff;
  display: block;
  padding: 5px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  font-size: 13px;
  right: -5px;
  top: -13px;
  text-align: center;
}
.btn-c {
  padding: 5px 25px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.15s linear;
  min-width: 180px;
  color: #000000;
  position: relative;
  text-decoration: none;
}
.btn-c:hover {
    text-decoration: none;
  }
.btn--dark {
  background-color: #fff;
  border: 2px solid #2bd49c;
  color: #000;
}
.btn--dark:hover {
    background-color: #2bd49c;
    border: 2px solid #fff;
    color: #ffffff;
  }
.btn--dark-bw {
  background: rgba(255, 255, 255, 0.6);
  border: 2px solid #ffffff;
  color: #000 !important;
}
.btn--dark-bw:hover {
    background-color: #2bd49c;
    color: #ffffff !important;
  }
.btn--underline {
  background-color: transparent;
  border: 2px solid transparent;
  text-decoration: underline;
  font-weight: 700;
  color: #000000;
}
.btn--underline:hover {
    transform: scale(1.02);
    text-decoration: underline;
    color: #000000;
  }
.btn--white {
  background-color: #ffffff;
  border: 2px solid #ffffff;
  color: #000000 !important;
}
.btn--white:hover {
    background-color: #f1f1f1;
    border-color: #f1f1f1;
    color: #000000;
  }
.btn--transparent {
  background-color: transparent;
  border: 2px solid #2bd49c;
  color: #2bd49c;
}
.btn--transparent:hover {
    color: #000000;
    background-color: #f1f1f1;
    border-color: #f1f1f1;
  }
.btn--white-bg {
  border-color: #000000;
  background-color: #ffffff;
  border: 2px solid #000000;
}
.btn--white-bg:hover {
    background-color: #000000;
    color: #ffffff;
  }
.upload--pdf {
  border: 1px solid #000000;
  background-color: transparent;
}
.upload--pdf:hover {
    background-color: #e4e0e0;
  }
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}
.btn--center {
  display: inline-block;
  margin: 0 auto;
}
.icon--right svg,
  .icon--right img {
    margin-left: 15px;
  }
.icon--left svg,
  .icon--left img {
    margin-right: 15px;
  }
.icon-invert {
  filter: invert(1);
}
.w--10 {
  width: 10% !important;
}
.w--20 {
  width: 20% !important;
}
.w--30 {
  width: 30% !important;
}
.w--40 {
  width: 40% !important;
}
.w--50 {
  width: 50% !important;
}
.w--50-r {
  width: 50% !important;
}
@media (max-width: 768px) {
.w--50-r {
    width: 100% !important
}
  }
.w--60 {
  width: 60% !important;
}
.w--70 {
  width: 70% !important;
}
.w--80 {
  width: 80% !important;
}
.w--90 {
  width: 90% !important;
}
.w--100 {
  width: 100% !important;
}
.db {
  display: block;
}
.btn--wrapper {
  padding: 5px;
  display: inline-block;
  vertical-align: top;
}
.bar {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 7px;
  background: #a19e9e;
  overflow: hidden;
  z-index: 300;
}
.bar div:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background: #ffffff;
  animation: box-1 2100ms cubic-bezier(0.65, 0.81, 0.73, 0.4) infinite;
}
.bar div:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background: #000000;
  animation: box-2 2100ms cubic-bezier(0.16, 0.84, 0.44, 1) infinite;
  animation-delay: 1150ms;
}
@keyframes box-1 {
  0% {
    left: -35%;
    right: 100%;
  }
  60%,
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes box-2 {
  0% {
    left: -200%;
    right: 100%;
  }
  60%,
  100% {
    left: 107%;
    right: -8%;
  }
}
.bg-gray {
  background-color: #f1f1f1;
}
.remove-button-33 {
  background-color: transparent;
  border: none;
  margin-top: 8px;
}
.btn-add-new {
  padding: 10px 15px;
  border: 2px solid #dfdfdf;
  border-radius: 8px;
  margin: 5px;
  width: calc(70% - 10px);
  text-align: left;
  background-color: #ffffff;
  color: #8c8c8c;
}
.portfolio-image {
  position: relative;
  background-color: #f1f1f1;
  height: 200px;
}
.portfolio-image img {
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
.portfolio-image .edit-portfolio {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.15s linear;
    padding: 15px;
  }
.portfolio-image .edit-portfolio svg {
      margin-top: 79px;
      color: #beb9b9;
      margin: 5px;
      opacity: 0;
    }
.portfolio-image .edit-portfolio svg:hover {
        color: #ffffff;
      }
.portfolio-image .edit-portfolio:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
.portfolio-image .edit-portfolio:hover svg {
        opacity: 1;
      }
.portfolio-add {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  cursor: pointer;
  position: relative;
  transition: all 0.15s linear;
}
.portfolio-add svg {
    margin-bottom: 5px;
    font-size: 22px;
  }
.portfolio-add p {
    margin: 0;
  }
.portfolio-add:hover {
    background-color: #7e7d7d;
    color: #ffffff;
  }
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  box-shadow: 0px 10px 13px #00000026;
  width: 100%;
  border: none !important;
}
.react-datepicker .react-datepicker__navigation--previous,
  .react-datepicker .react-datepicker__navigation--next {
    top: 30px;
  }
.react-datepicker .react-datepicker__month-container {
    z-index: 100;
    background-color: #ffffff;
    width: 100%;
  }
.react-datepicker .react-datepicker__month-container .react-datepicker__header {
      background-color: #ffffff;
      padding-top: 30px;
    }
.react-datepicker .react-datepicker__month-container .react-datepicker__current-month {
      color: #43425d;
      font-weight: 600;
      margin-bottom: 15px;
    }
.react-datepicker .react-datepicker__month-container .react-datepicker__day {
      width: 40px;
      height: 40px;
      padding: 8px 3px;
      outline: none;
    }
.react-datepicker .react-datepicker__month-container .react-datepicker__day--selected {
      background-color: #000000;
      border-radius: 50%;
    }
.portal--calendar .react-datepicker {
    box-shadow: 0px 10px 13px #00000026;
    width: 100%;
    border: none !important;
  }
.portal--calendar .react-datepicker .react-datepicker__navigation--previous,
    .portal--calendar .react-datepicker .react-datepicker__navigation--next {
      top: 30px;
    }
.portal--calendar .react-datepicker .react-datepicker__month-container {
      z-index: 100;
      background-color: #ffffff;
      width: 100%;
    }
.portal--calendar .react-datepicker .react-datepicker__month-container .react-datepicker__header {
        background-color: #ffffff;
        padding-top: 30px;
      }
.portal--calendar .react-datepicker .react-datepicker__month-container .react-datepicker__current-month {
        color: #43425d;
        font-weight: 600;
        margin-bottom: 15px;
      }
.portal--calendar .react-datepicker .react-datepicker__month-container .react-datepicker__day {
        width: 50px;
        height: 50px;
        padding: 0 !important;
        outline: none;
      }
.portal--calendar .react-datepicker .react-datepicker__month-container .react-datepicker__day--selected {
        background-color: #000000;
        border-radius: 50%;
      }
.images-to-load {
  margin-top: 15px;
  margin-bottom: 15px;
}
.images-to-load .image-box {
    width: 33%;
    display: inline-block;
    vertical-align: top;
    padding: 5px;
    height: 130px;
    position: relative;
  }
.images-to-load .image-box .remove-image-button {
      position: absolute;
      right: 5px;
      top: 5px;
      color: #ffffff;
      padding: 5px;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.7);
    }
.images-to-load .image-box img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
.btn--add--image {
  margin: 5px;
  border: none;
  padding: 5px 15px;
  background-color: #f1f1f1;
  transition: all 0.15s linear;
}
.btn--add--image:hover {
    background-color: #d3d0d0;
  }
.ril-toolbar-left {
  color: #ffffff;
  font-size: 26px;
}
.react-datepicker-popper {
  z-index: 44443 !important;
}
.ReactModal__Overlay {
  z-index: 1071 !important;
}
.modal {
  z-index: 1086;
}
.form-details {
  display: flex;
  padding: 5px 7px;
  align-items: flex-start;
}
.form-details .form-remember {
    text-align: left;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    margin: 0;
  }
.form-details .form-remember input {
      margin-right: 5px;
    }
.form-details .forgot-password {
    flex: 0 0 50%;
    margin-top: -2px;
    text-align: right;
  }
.form-details .forgot-password .forgot-password-link {
      font-weight: 500;
      color: #000000;
      font-size: 14px;
    }
.quill .ql-editor {
    min-height: 100px !important;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
  }
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.icon--left--text {
  display: flex;
  align-items: center;
}
.icon--left--text img {
    margin-right: 10px;
    width: 45px;
  }
.icon--left--text p {
    margin: 0;
    font-weight: 600;
  }
.for-comp-image-1 img {
    width: 100%;
    height: 530px;
    object-fit: cover;
  }
@media (max-width: 920px) {
.for-comp-image-1 img {
      width: 100%;
      height: 250px
  }
    }
.for-comp-image-2 img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
@media (max-width: 920px) {
.for-comp-image-2 {
    margin-top: 0
}

    .for-comp-image-2 img {
      width: 100%;
      height: 250px;
    }
  }
.for-comp-image-3 img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
@media (max-width: 920px) {
.for-comp-image-3 img {
      width: 100%;
      height: 250px
  }
    }
main {
  padding: 0 !important;
}
.text_primary {
  color: #2bd49c;
}
.text_seconday {
  color: #454f63;
}
.text_gray {
  color: #c7cad1;
}
.slide-in-left-enter {
  transform: translateX(-100%);
}
.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}
.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}
.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}
.slide-in-top-enter {
  transform: translateY(-100%);
}
.slide-in-top-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}
.slide-in-top-exit {
  transform: translateY(0%);
  opacity: 1;
}
.slide-in-top-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 200ms;
}
.slide-in-left-enter {
  transform: translateX(-100%);
}
.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}
.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}
.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}
.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.avatar_label {
    background-color: #e6e8f0;
    width: 83px;
    height: 83px;
    border-radius: 50%;
    color: #454f63;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    text-align: center;
  }
.avatar_label :hover {
      cursor: pointer;
    }
.avatar_label img {
      position: absolute;
      width: 83px;
      height: 83px;
      border-radius: 50%;
      object-fit: cover;
    }
.avatar_label input {
      background: unset;
      border-color: inherit;
      border-width: 0;
      border-radius: 0;
      padding: 0;
      font-size: unset;
      line-height: inherit;
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
      z-index: 99999;
    }
.avatar_label svg {
      width: 20px;
      height: 20px;
      margin-bottom: 5px;
    }
.inputField {
  margin-bottom: 1rem;
}
.inputField_wrapper {
    display: flex;
    justify-content: flex-start;
    flex-flow: row;
    align-items: center;
    border-bottom: 1px solid #f4f4f6fd;
  }
.inputField_wrapper input:focus {
      outline: none !important;
      border-color: none !important;
      box-shadow: none !important;
    }
.inputField_input {
    color: #78849e;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 3px 0;
    font-size: 14px !important;
    line-height: 17px !important;
  }
.inputField_input ::placeholder {
      color: #78849e;
      font-size: 14px;
    }
.inputField_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2bd49c;
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
.inputField_divider {
    width: 2px;
    height: 100%;
    background-color: #f4f4f6;
  }
.inputField .inputField {
    margin: 0;
  }
.availableDates .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    overflow: auto;
  }
@media (min-width: 767.98px) {
.availableDates .modal-content {
      width: 80%;
      padding: 30px
  }
    }
.availableDates .modal-title {
    text-align: center;
    padding-top: 20px;
  }
.availableDates .btn {
    width: 80%;
    border-radius: 12px;
    height: 50px;
  }
.availableDates .Calendar .Calendar__weekRow .-blank{
        background-color: unset !important;

      }
.availableDates .Calendar .Calendar__weekRow .-disabled {
        background-color: #ff0033a6 !important;
      }
.availableDates .Calendar .Calendar__weekRow .-weekend{
        background-color: unset !important;
        color: #ff0033a6 !important;
        cursor: default !important;
      }
.availableDates .Calendar .Calendar__weekRow .-selectedStart,
      .availableDates .Calendar .Calendar__weekRow .-selectedEnd {
        background-color: #2bd49c !important;
      }
.availableDates .crew-calendar {
    box-shadow: none;
  }
.availableDates .crew-calendar .Calendar__weekRow .-ltr{
        background-color: #2bd49c ;
        color: #fff;
      }
.availableDates .crew-calendar .Calendar__weekRow .-blank{
        background-color: unset !important;

      }
.availableDates .crew-calendar .Calendar__weekRow .-disabled {
        background-color: #ff0033a6 !important;
      }
.availableDates .crew-calendar .Calendar__weekRow .-weekend{
        background-color: unset !important;
        color: #ff0033a6 !important;
        cursor: default !important;
      }
.availableDates .crew-calendar .Calendar__weekRow .-selectedStart,
      .availableDates .crew-calendar .Calendar__weekRow .-selectedEnd {
        background-color: #ff0033cf !important;
      }
.availableDates .crew-calendar .Calendar__weekRow .-selectedBetween{
        background-color: #ff00337a !important;
      }
.availableDates .modal-dialog-centered {
    display: flex;
    justify-content: center;
  }
.availableDates_icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #ff0033a6 !important;
  }
.availableDates .modal-header {
    display: flex;
    align-items: center;
    padding: 0;
  }
.freeAvailableDates_icon{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #2bd49c !important;
}
.minDay .Calendar {
    box-shadow: none;
  }
.minDay .Calendar .Calendar__weekRow .-disabled {
        background-color: #757575 !important;
      }
.comming__soon {
  height: 65vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #454f63;
}
.comming__soon h2 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1.5;
  }
.comming__soon h4 {
    font-size: 16px;
  }
.comming__soon svg {
    font-size: 80px;
  }
.explore__card__image {
  object-fit: cover;
  border-radius: 12px;
}
.explore__card__image_company {
    width: 79px;
    height: 79px;
    box-shadow: 0 3px 15px #00000029;
  }
.explore__card__image_user {
    width: 48px;
    height: 48px;
    margin-top: 5px;
  }
.notification__item {
  display: flex;
  align-items: center;
  gap: 14px;
}
.notification__item:not(.notification__item:last-child) .notification__content {
      border-bottom: 1px solid #f4f4f6fd;
    }
.notification__item .notification__content {
    padding: 15px 0;
  }
.notification__item .notification__content h3 {
      font-size: 16px;
      color: #454f63;
    }
.notification__item .notification__content a {
      font-size: 12px;
      color: #959dad;
      text-decoration: none;
    }
.notification__item .notification__content a:hover {
        color: #2f3135;
      }
.job__card__image {
  width: 97px;
  height: 97px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 3px 15px #00000029;
}
.selectField {
  margin-bottom: 0.5rem;
}
.selectField_wrapper {
    border-bottom: 1px solid #f4f4f6fd;
  }
.selectField_wrapper select:focus {
      outline: none !important;
      border-color: none !important;
      box-shadow: none !important;
    }
.selectField_input {
    color: #78849e;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 3px 0;
    font-size: 14px !important;
    line-height: 17px !important;
  }
.selectField_input ::placeholder {
      color: #78849e;
      font-size: 14px;
    }
.selectField_icon {
    color: #2bd49c;
    width: 20px;
    height: 20px;
  }
.selectField .css-tlfecz-indicatorContainer {
    padding: 0 !important;
    padding-left: 5px;
  }
.selectField_arrow {
    width: 14px;
    height: 14px;
  }
.checkbox_wrapper {
    border-bottom: 1px solid #f4f4f6fd;
  }
.checkbox_wrapper input:focus {
      outline: none !important;
      border-color: none !important;
      box-shadow: none !important;
    }
.checkbox_wrapper label::after,
    .checkbox_wrapper label::before {
      display: none !important;
    }
.checkbox_wrapper label:hover {
      cursor: pointer;
    }
.checkbox_input {
    color: #78849e;
    outline: none;
    border: none;
    border-radius: 0;
    padding: 3px 0;
    font-size: 18px;
    line-height: 22px;
  }
.checkbox_active {
    color: #2bd49c !important;
  }
.checkbox_icon {
    color: #2bd49c;
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
.error-notice {
  margin: 5px; /* Make sure to keep some distance from all sides */
}
.oaerror {
  width: 80%;
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  margin: 10px auto;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
.danger {
  border-left-color: #d9534f; /* Left side border color */
  background-color: rgba(
    217,
    83,
    79,
    0.1
  ); /*Same color as the left border with reduced alpha to 0.1*/
}
.danger strong {
  color: #d9534f;
}
.fluid-wrapper {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f8f8f8;
}
.btn {
  display: block;
  padding: 16px;
  border: none;
  outline: none;
  font-weight: bold;
}
.btn:focus {
    box-shadow: none;
  }
.btn:hover {
    cursor: pointer;
    opacity: 0.9;
  }
.btn__text {
    background-color: none;
    color: #2bd49c;
    padding: 0;
  }
.btn__contained {
    background-color: #2bd49c;
  }
.btn__outlined {
    background-color: none;
    border: 1px solid #2bd49c;
    color: #454f63;
  }
.btn__white {
    background-color: #fff;
    color: #78849e;
  }
.btn__primary {
    background-color: #2bd49c;
    color: #fff;
  }
.btn__primary:hover {
      color: white;
    }
.btn__secondary {
    background-color: #e6e8f0;
    color: #241239;
  }
.btn__fullWidth {
    min-width: 100%;
  }
.app-backdrop {
  position: fixed;
  top: 0;
  height: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.loader-bg {
  width: 100%;
  height: 100%;
}
@media (max-width: 992px) {
.loader-bg {
    padding: 50px 0 125px
}
  }
.loader,
.loader:before,
.loader:after {
  position: absolute;
  top: 50%;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
  margin-bottom: 50px;
}
.loader {
  color: #000;
  font-size: 10px;
  margin: -30px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
.container {
  margin: auto;
  padding: 0;
  max-width: 1200px;
  width: 100%;
}
@media (max-width: 1440px) {
.container {
    padding: 0 15px
}
  }
.container-wrapper {
    padding: 50px 0 !important;
  }
@media (max-width: 768px) {
.container-wrapper {
      padding: 50px 15px !important
  }
    }
.progressing-message-spinner {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 15px;
  border-radius: 0 5px 5px 0;
  margin: 5px 0;
  color: red;
  font-weight: 600;
}
.progressing-message-spinner .spinner-border {
    margin-right: 15px;
    flex: 0 0 2rem;
  }
.delete-modal {
  text-align: center;
  padding: 40px 20px;
}
.add-pdf-modal {
  text-align: center;
  padding: 40px 20px;
}
.add-pdf-modal .modal-header {
    border: none;
    padding-top: 0;
  }
.addJob .modal-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    overflow: auto;
  }
@media (min-width: 767.98px) {
.addJob .modal-content {
      padding: 30px;
      width: 80%
  }
    }
/* display */
.addJob ::-webkit-scrollbar {
    display: none;
  }
.addJob_actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
  }
.addJob_actions .btn {
      width: 100%;
      padding: 40px;
      height: 50px;
      border-radius: 10px;
    }
@media (min-width: 767.98px) {
.addJob_actions {
      flex-flow: row
  }
      .addJob_actions button:first-child {
        margin-right: 5px;
      }
      .addJob_actions button:last-child {
        margin-left: 5px;
      }
    }
.addJob_date {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
  }
.addJob_date h6 {
      color: #c7cad1;
      text-align: left;
    }
.addJob_date div > label:first-child {
      margin-right: 30px;
    }
.addJob_delete {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    border-color: #fe1515 !important;
    padding: 40px;
    height: 50px;
    border-radius: 10px;
  }
.addJob_delete span {
      color: #fe1515;
      font-size: 14px;
      font-weight: 700;
    }
.addJob_delete svg {
      color: #fe1515;
      width: 24px;
      height: 24px;
    }
.addJob_delete:hover {
      border-color: rgba(254, 21, 21, 0.8) !important;
    }
.addJob_delete:hover span,
      .addJob_delete:hover svg {
        color: rgba(254, 21, 21, 0.8);
      }
.addJob .modal-title {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
@media (min-width: 767.98px) {
.addJob .modal-title {
      width: 60%
  }
    }
.addJob .modal-dialog-centered {
    display: flex;
    justify-content: center;
  }
.addJob_btn {
    border: none;
    font-size: 14px;
    color: #78849e;
    background-color: transparent !important;
  }
.addJob_btn svg {
      color: #2bd49c;
      width: 22px;
      height: 22px;
    }
.addJob_hidden {
    visibility: hidden;
  }
.addJob_visible {
    visibility: visible;
  }
.AddWorkExperience .modal-header {
    display: flex;
    align-items: center;
    padding: 0;
    padding-bottom: 10px;
    border: none;
  }
.modal-close-job{
  position: absolute;
  top: 0;
  right: 17px;
}
.jobToolbar {
  border: 1px solid #ccc;
  padding: 10px;
}
.jobEditor {
  border: 1px solid #ccc;
  padding: 10px;
  height: 200px !important;
  overflow: auto;
}
.jobEditor span{
    display: inline-block !important;
      overflow: hidden !important;
      word-break: break-all !important;
  }
.job__description span{
    background-color: transparent !important;
    display: inline-block !important;
      overflow: hidden !important;
      word-break: break-all !important;
  }
.job__description p{
    line-height: 26px;
  }
.addJob_date{
  display: none;
}
.avatarProfile_img {
    width: 128px;
    height: 128px;
    border-radius: 22px;
    object-fit: cover;
  }
@media (max-width: 767.98px) {
.avatarProfile_img {
      width: 100px;
      height: 100px
  }
    }
.createProfile .modal-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    overflow: auto;
  }
@media (min-width: 767.98px) {
.createProfile .modal-content {
      width: 80%;
      padding: 30px
  }
    }
/* display */
.createProfile ::-webkit-scrollbar {
    display: none;
  }
.createProfile_btn {
    padding: 40px;
    height: 50px;
    border-radius: 10px;
  }
.createProfile .modal-title {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
@media (min-width: 767.98px) {
.createProfile .modal-title {
      width: 60%
  }
    }
.createProfile .modal-dialog-centered {
    display: flex;
    justify-content: center;
  }
.createProfile .feed__edit {
    margin-bottom: 10px;
  }
.createProfile_file {
    background: #f7f7fa;
    padding: 8px;
    border-radius: 4px;
  }
.createProfile_file label {
      cursor: pointer;
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
    }
.createProfile_file label button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        padding: 0;
        margin-right: 10px;
      }
.createProfile_file label p {
        margin: 0;
        color: #959dad;
        font-weight: 400;
        font-size: 13px;
      }
.createProfile_file input {
      visibility: hidden;
      width: 0;
      display: none;
    }
.createProfile_hidden {
    visibility: hidden;
  }
.createProfile_visible {
    visibility: visible;
  }
.empty__data {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.empty__data svg {
    width: 100px;
    height: 100px;
  }
.explore__card {
  background-color: #fff;
  padding: 28px 37px;
  border-radius: 12px;
}
@media (max-width: 500px) {
.explore__card {
    padding: 14px 18px
}
  }
.explore__card__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
.explore__card__header_info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
    }
.explore__card__header_info > div h2 {
          font-size: 16px;
          font-weight: bold;
          color: #454f63;
          margin-bottom: 8px;
        }
.explore__card__header_info > div p {
          font-size: 14px;
          color: #78849e;
          margin-bottom: 0;
        }
.explore__card__header_jobsNumber {
      text-align: center;
    }
.explore__card__header_jobsNumber > div {
        background-color: #454f632e;
        padding: 9px 9px 8px 9px;
        border-radius: 8px;
      }
.explore__card__header_jobsNumber > div span {
          color: #454f63;
          font-weight: bold;
        }
.explore__card__header_jobsNumber > span {
        color: #bab8c6;
        font-size: 10px;
      }
.explore__card__divider {
    color: #f4f4f6fd;
  }
.explore__card__cta {
    margin: 28px auto 0;
  }
.notification__list {
  padding-top: 30px;
  height: 550px;
  overflow-y: scroll;
}
.notification__info {
  padding-top: 30px;
}
.notification__info button {
    width: 276px;
    border-radius: 12px;
  }
.notification__info h3 {
    font-size: 28px;
    color: #454f63;
  }
.notification__info p {
    margin: 0;
    font-size: 14px;
    padding: 24px 0;
    color: #78849e;
  }
.headerbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 16px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e6e8f0;
}
.headerbar__title-wrapper {
    display: flex;
    gap: 15px;
  }
.headerbar__backButton {
    background-color: transparent;
    border: none;
    outline: none;
    color: #241239;
  }
.headerbar__title {
    color: #454f63;
    font-size: 40px;
    font-weight: bold;
    text-transform: capitalize;
  }
.headerbar__action {
    background-color: #fff;
    padding: 13px 15px;
    border: 1px solid #e6e8f0;
    border-radius: 11px;
    position: relative;
  }
.headerbar__action svg {
      width: 19px;
      height: 22px;
      color: #454f63;
    }
.headerbar__action .notifications-alert{
      background: red;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      position: absolute;
      top: -6px;
      right: -10px;
      display: flex;
      text-align: center;
      justify-content: center;
    }
.headerbar__action .notifications-alert p{
      width: -moz-fit-content;
      width: fit-content;
      font-size: 15px;
      color: #ffffff;
      margin-bottom: 0;
      line-height: 27px;
    }
.headerbar__action-active {
      background-color: #241239;
    }
.headerbar__action-active path {
        fill: #fff;
      }
.headerbar__action_divider {
      margin-top: 16px;
      border: 1px solid #e6e8f0;
    }
.notifications{
  position: absolute;
  z-index: 999;
  right: 69px;
  top: 86px;
}
.notifications .notifications__wrapper{
   background-color: #f6f6f6 !important;
   max-width: 470px;
   border: 3px solid #e6e8f0;
  }
.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.pagination button {
    padding: 10px;
    width: 3rem;
  }
.pagination .active {
    background-color: #29b692;
  }
.job__card {
  display: flex;
  align-items: center;
}
.job__card:hover {
    text-decoration: none;
  }
.job__card{

  min-height: 114px;
  margin-left: 40px;
  background-color: #fff;
  border: 1px solid #e6e8f0;
  border-radius: 12px;
}
.job__card__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    margin-left: -40px;
  }
.job__card__info p {
      font-size: 12px;
      color: #bab8c6;
      margin: 0;
    }
.job__card__info h3 {
      font-size: 16px;
      color: #454f63;
      margin: 6px 0;
    }
.job__card__info h4 {
      font-size: 14px;
      color: #78849e;
      margin: 0;
    }
.job__card__info .Active {
      color: #2bd49c;
    }
.job__card__info .Upcoming {
      color: #d4442b;
    }
.job__card__info .past {
      color: #2c2c2c;
    }
.requestSent .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    overflow: auto;
  }
@media (min-width: 767.98px) {
.requestSent .modal-content {
      padding: 30px;
      width: 80%
  }
    }
.requestSent .modal-title {
    text-align: center;
    padding-top: 20px;
  }
.requestSent .btn,
  .requestSent p {
    width: 100%;
    border-radius: 12px;
    height: 50px;
  }
@media (min-width: 767.98px) {
.requestSent .btn,
  .requestSent p {
      width: 80%
  }
    }
.requestSent p {
    text-align: center;
    color: hsla(268, 52%, 15%, 0.5);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.14px;
  }
.requestSent_icon {
    background-color: rgba(43, 212, 156, 0.4);
    border-radius: 50%;
    padding: 20px;
  }
.requestSent_icon span {
      background-color: #2bd49c;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
.requestSent_icon svg {
      position: absolute;
      color: #a2ead0;
      height: 30px;
      width: 30px;
    }
.requestSent .modal-dialog-centered {
    display: flex;
    justify-content: center;
  }
.sort .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    overflow: auto;
  }
@media (min-width: 767.98px) {
.sort .modal-content {
      padding: 30px;
      width: 80%
  }
    }
.sort_actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
  }
.sort_actions .btn {
      width: 100%;
      padding: 40px;
      height: 50px;
      border-radius: 10px;
    }
@media (min-width: 767.98px) {
.sort_actions {
      flex-flow: row
  }
      .sort_actions button:first-child {
        margin-right: 5px;
      }
      .sort_actions button:last-child {
        margin-left: 5px;
      }
    }
.sort .modal-title {
    width: 100%;
    text-align: center;
  }
@media (min-width: 767.98px) {
.sort .modal-title {
      width: 60%
  }
    }
.sort .modal-dialog-centered {
    display: flex;
    justify-content: center;
  }
.sort_hidden {
    visibility: hidden;
  }
.sort_visible {
    visibility: visible;
  }
.nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 682px;
}
.nav__link {
    background-color: #fff;
    color: #78849e;
    border: 1px solid #e6e8f0;
    border-radius: 12px 12px 0 0;
    padding: 16px 55px;
    text-align: center;
  }
.nav__link:hover {
      color: #222;
      text-decoration: none;
    }
.nav__link-active {
      background-color: #2bd49c;
      color: #fff;
    }
.nav__link-active:hover {
        color: #fff;
      }
.job__cards {
  background-color: #fff;
  padding: 30px;
}
@media screen and (max-width: 1088px) {
  .nav {
    width: 220px;
  }
}
@media screen and (max-width: 704px) {
  .nav {
    grid-template-columns: 1fr;
    width: 100%;
  }

    .nav__link {
      border-radius: 0;
      margin: 10px 0;
    }
}
.textDetails_p {
    color: #7a869fa6;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0px;
  }
.textDetails_h3 {
    color: #454f63;
    font-size: 16px;
    line-height: 19px;
  }
.textDetails_icon {
    color: #2bd49c;
  }
.textDetails_icon > svg {
      width: 24px;
      height: 24px;
    }
.textDetails input {
    border: none;
    color: #78849e;
    width: 80%;
  }
.textDetails input.focus-visible {
    outline: none !important;
    border-color: #2bd49c !important;
  }
.textDetails input:focus-visible {
    outline: none !important;
    border-color: #2bd49c !important;
  }
.textDetails_borderBottom {
    border-bottom: 1px solid #f4f4f6fd;
  }
.searchbar__title {
    color: rgba(120, 132, 158, 57%);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
.searchbar__form__wrapper {
    margin-top: 16px;
  }
.profileActions {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.profileActions a {
    text-decoration: none;
  }
.profileActions .btn__text {
    font-size: 17px;
  }
.profileActions .btn__text:hover {
      color: #2bd49ce7;
    }
.profileActions_chat {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 26px;
  }
.profileActions_chat span {
      font-size: 14px;
      line-height: 24px;
      color: #454f63;
      font-weight: 400;
    }
.profileActions_chat svg {
      margin-right: 5px;
      color: #2bd49c;
      width: auto;
      height: 29px;
    }
.profileActions_chat:hover {
      cursor: pointer;
    }
.crewProfileActions {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.crewProfileActions_request {
    font-size: 14px;
    border-radius: 12px;
    width: 90%;
  }
@media (min-width: 767.98px) {
.crewProfileActions_request {
      width: 60%
  }
    }
.search__job {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;

}
.search__job .search__job__input_control {
    background-color: #fff;
    border: 1px solid #e6e8f0;
    padding: 0 18px;
    border-radius: 12px;
  }
.search__job .search__job__input_control .selectField_wrapper{
      border: none !important;
    }
.search__job .search__job__input_control .inputField_wrapper {
      border: none;
      gap: 16px;
      height: 20px;
    }
.search__job .search__job__input_control .inputField_icon {
      margin-right: 0;
      font-size: x-large;
    }
.search__job .btn {
    border-radius: 12px;
    text-transform: uppercase;
  }
.search--location {
  position: relative;
}
.search--location .autocomplete-dropdown-container{
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #e6e8f0;
    overflow-y: auto;
    border-radius: 12px;
    z-index:999999;
  }
.search--location .autocomplete-dropdown-container div:last-child {
        border-bottom: 0;
      }
.search--location .autocomplete-dropdown-container div:hover {
        background-color: rgba(25, 80, 121, 0.4);
      }
.search__results__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.search__results__card:not(:last-child) {
    border-bottom: 1px solid #e6e8f0;
  }
.search__results__card img {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 12px;
  }
.search__results__card .card__info {
    flex: 1 1;
  }
.search__results__card .card__info h3 {
      font-weight: bold;
      color: #454f63;
      font-size: 16px;
    }
.search__results__card .card__info p {
      color: #78849e;
      font-size: 14px;
      margin: 0;
    }
.search__results__card button {
    width: 160px;
    border-width: 2px;
    border-radius: 12px;
    padding: 14px 38px 12px;
    font-size: 12px;
    text-transform: uppercase;
  }
.crewSkills {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
}
@media (min-width: 767.98px) {
.crewSkills {
    width: 80%
}
  }
.crewSkills .badge-primary {
    color: #2bd49c !important;
    padding: 5px 10px;
    background-color: transparent;
    border: 1px solid #2bd49c;
    font-weight: 500;
    font-size: 13px;
  }
.crewSkills_btn {
    background-color: transparent;
    padding: 0 !important;
    border: none;
    color: #2bd49c;
  }
.crewSkills_btn :hover {
      color: #2bd49cb0 !important;
    }
.crewSkills_btn svg {
      height: 26px;
      width: 26px;
    }
.crewSkills_btn svg > * {
        stroke: #2bd49c;
      }
.crewSkills_add {
    width: max-content;
    background-color: transparent;
    border: 1px solid #2bd49c;
  }
.crewSkills_add button {
      font-weight: 500;
      font-size: 12px;
      color: #2bd49c;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
    }
.crewSkills_add button svg {
        height: 20px;
        width: 20px;
      }
.crewSkills_add button:hover {
      color: #2bd49cb0 !important;
    }
.crewSkills_add button:hover svg > * {
          stroke: #2bd49cb0 !important;
        }
.crewSkills_wrap {
    min-width: 220px;
  }
.crewSkills_wrap .inputField_wrapper {
      min-width: 200px !important;
    }
.search__crew {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
}
.search__crew .search__crew__input_control {
    position: relative;
    background-color: #fff;
    border: 1px solid #e6e8f0;
    padding: 0 18px;
    border-radius: 12px;
  }
.search__crew .search__crew__input_control select {
      /* for Firefox */
      -moz-appearance: none;
      /* for Chrome */
      -webkit-appearance: none;
    }
/* For IE10 */
.search__crew .search__crew__input_control select::-ms-expand {
      display: none;
    }
.search__crew .search__crew__input_control .inputField_wrapper .selectField {
        margin-bottom: 1rem;
      }
.search__crew .search__crew__input_control .inputField_wrapper .selectField_wrapper {
        border: none;
      }
.search__crew .search__crew__input_control_datepicker {
      outline: none;
      border: none;
      width: 100%;
      height: 100%;
      background-color: transparent;
    }
.search__crew .search__crew__input_control .inputField_wrapper {
      border: none;
      gap: 16px;
      height: 20px;
    }
.search__crew .search__crew__input_control .inputField_icon {
      margin-right: 0;
      font-size: x-large;
    }
.search__crew .btn {
    border-radius: 12px;
    text-transform: uppercase;
  }
.search__crew .form-control:disabled,
  .search__crew .form-control[readonly] {
    background-color: transparent;
  }
.search__crew .error__text {
    position: absolute;
    font-size: 10px;
    bottom: -15px;
    left: 5px;
  }
.search--location {
  position: relative;
}
.search--location .autocomplete-dropdown-container{
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #e6e8f0;
    overflow-y: auto;
    border-radius: 12px;
    z-index:999999;
  }
.search--location .autocomplete-dropdown-container div:last-child {
        border-bottom: 0;
      }
.search--location .autocomplete-dropdown-container div:hover {
        background-color: rgba(25, 80, 121, 0.4);
      }
.sendRequest .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    overflow: auto;
  }
@media (min-width: 767.98px) {
.sendRequest .modal-content {
      width: 80%;
      padding: 30px
  }
    }
.sendRequest .btn__link {
    font-weight: 500;
  }
.sendRequest_actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
  }
.sendRequest_actions .btn {
      width: 100%;
      padding: 40px;
      height: 50px;
      border-radius: 10px;
    }
@media (min-width: 767.98px) {
.sendRequest_actions {
      flex-flow: row
  }
      .sendRequest_actions button:first-child {
        margin-right: 5px;
      }
      .sendRequest_actions button:last-child {
        margin-left: 5px;
      }
    }
.sendRequest .modal-title {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
@media (min-width: 767.98px) {
.sendRequest .modal-title {
      width: 60%
  }
    }
.sendRequest .modal-dialog-centered {
    display: flex;
    justify-content: center;
  }
.sendRequest_btn {
    border: none;
    font-size: 14px;
    color: #78849e;
    background-color: transparent !important;
  }
.sendRequest_btn svg {
      color: #2bd49c;
      width: 22px;
      height: 22px;
    }
.sendRequest .btn__link:hover {
    cursor: pointer;
    color: #2bd49c !important;
  }
.sendRequest_hidden {
    visibility: hidden;
  }
.sendRequest_visible {
    visibility: visible;
  }
.feeds {
  width: 95%;
}
@media (min-width: 767.98px) {
.feeds {
    width: 80%
}
  }
.feed {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 25px;
  position: relative;
  margin-bottom: 30px;
}
.feed__edit button {
      margin-top: 7px;
    }
.feed:last-child .feed__line {
      display: none;
    }
.feed__content {
    flex: 1 1;
  }
.feed__line {
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #959dad;
    top: 28px;
    left: 13px;
  }
.feed__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2bd49c;
    border-radius: 6px;
    padding: 4px;
  }
.feed__icon__edit {
      background-color: #c7cad1;
    }
.feed__icon svg {
      color: #fff;
      height: 20px;
      width: 20px;
    }
.feed p {
    margin: 0;
    line-height: 1.2;
  }
.feed__title {
    font-weight: bold;
    color: #454f63;
    padding-top: 5px;
    padding-bottom: 10px;
  }
.feed__description {
    color: #78849e;
    font-size: 14px;
  }
.feed__helptext {
    color: #78849e;
    font-size: 12px;
  }
.feed__actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }
.accountSettings .addJob_actions {
    justify-content: flex-end;
  }
.accountSettings .addJob_actions .btn {
      width: auto;
      height: auto;
      padding: 10px 15px;
    }
.accountSettings .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #2bd49c;
    background-color: #2bd49c;
  }
.sidebar__items {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar__items .sidebar__item {
    margin-bottom: 58px;
  }
.sidebar__items .sidebar__item:hover .sidebar__item_link {
        color: #454f63;
      }
.sidebar__items .sidebar__item:hover path {
        fill: #454f63;
      }
.sidebar__items .sidebar__item_link {
    display: flex;
    align-items: center;
    gap: 20px;

    color: #454f63;
    text-decoration: none;
  }
.sidebar__items .sidebar__item_link-active {
      color: #454f63;
      font-weight: bold;
    }
.sidebar__items .sidebar__item_link-active path {
        fill: #454f63;
      }
@media screen and (max-width: 1024px) {
  .sidebar__items {
    padding: 2.4rem;
  }
}
.updateCard .modal-content {
    display: flex;
    justify-content: flex-start;
    border-radius: 20px;
    width: 100%;
    padding: 10px;
    overflow: auto;
  }
@media (min-width: 767.98px) {
.updateCard .modal-content {
      padding: 30px;
      width: 80%
  }
    }
/* display */
.updateCard ::-webkit-scrollbar {
    display: none;
  }
.updateCard_actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
  }
.updateCard_actions .btn {
      width: 100%;
      padding: 40px;
      height: 50px;
      border-radius: 10px;
    }
@media (min-width: 767.98px) {
.updateCard_actions {
      flex-flow: row
  }
      .updateCard_actions button:first-child {
        margin-right: 5px;
      }
      .updateCard_actions button:last-child {
        margin-left: 5px;
      }
    }
.updateCard_date {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
  }
.updateCard_date h6 {
      color: #c7cad1;
      text-align: left;
    }
.updateCard_date div > label:first-child {
      margin-right: 30px;
    }
.updateCard_delete {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    border-color: #fe1515 !important;
    padding: 40px;
    height: 50px;
    border-radius: 10px;
  }
.updateCard_delete span {
      color: #fe1515;
      font-size: 14px;
      font-weight: 700;
    }
.updateCard_delete svg {
      color: #fe1515;
      width: 24px;
      height: 24px;
    }
.updateCard_delete:hover {
      border-color: rgba(254, 21, 21, 0.8) !important;
    }
.updateCard_delete:hover span,
      .updateCard_delete:hover svg {
        color: rgba(254, 21, 21, 0.8);
      }
.updateCard .modal-title {
    width: 100%;
    text-align: center;
    padding-top: 20px;
  }
.updateCard .modal-dialog-centered {
    display: flex;
    justify-content: center;
  }
.updateCard_btn {
    border: none;
    font-size: 14px;
    color: #78849e;
    background-color: transparent !important;
  }
.updateCard_btn svg {
      color: #2bd49c;
      width: 22px;
      height: 22px;
    }
.updateCard_hidden {
    visibility: hidden;
  }
.updateCard_visible {
    visibility: visible;
  }
.AddWorkExperience .modal-header {
    display: flex;
    align-items: center;
    padding: 0;
    padding-bottom: 10px;
    border: none;
  }
.explore-card {
  margin-top: 42px;
}
.explore-card__title {
    color: rgba(120, 132, 158, 57%);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
.explore-card__cardWrapper {
    margin: 26px 0 100px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 18px;
  }
.profileDetails_p {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
.profileDetails_editBtn {
    position: absolute;
    right: 30px;
    top: 30px;
  }
.profileDetails_editBtn svg {
      width: 30px;
      height: 30px;
    }
.profileDetails_divider {
    height: 100%;
  }
.profileDetails_edit_input {
      padding: 0 10px;
      width: 90%;
      height: 100%;
      overflow: auto;
      margin-bottom: 80px;
    }
/* display */
.profileDetails_edit ::-webkit-scrollbar {
      display: none;
    }
.profileDetails_edit_btn {
      width: inherit;
      border-radius: 12px;
      border: 2px solid #2bd49c;
      font-size: 16px;
    }
.profileDetails_edit_btns {
      box-shadow: 0px -7px 7px #0000000b;
      position: absolute;
      bottom: 0;
      background-color: white;
      right: 0;
      left: 8px;
      border-bottom-right-radius: 12px;
    }
.profileDetails_edit_btns a {
        width: 100%;
        font-size: 14px;
      }
.profileDetails_edit_btns a button {
          padding: 7px !important;
        }
.profileDetails_edit .inputField {
      margin-bottom: 5px;
    }
@media (min-width: 767.98px) {
.profileDetails_edit {
      border-left: 1px solid #e6e8f0
  }
      .profileDetails_edit_input {
        width: 70%;
        max-height: 400px;
      }
    }
.profileDetails_edit_file {
      background: #f7f7fa;
      padding: 8px;
      border-radius: 4px;
    }
.profileDetails_edit_file label {
        cursor: pointer;
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
      }
.profileDetails_edit_file label button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          padding: 0;
          margin-right: 10px;
        }
.profileDetails_edit_file label p {
          margin: 0;
          color: #959dad;
          font-weight: 400;
          font-size: 13px;
        }
.profileDetails_edit_file input {
        visibility: hidden;
        width: 0;
        display: none;
      }
.jobs {
  margin-top: 42px;
}
.jobs__title {
    color: rgba(120, 132, 158, 57%);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
.jobs__jobsWrapper {
    margin-top: 26px;
    margin-bottom: 100px;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    justify-items: stretch;
    gap: 59px;
  }
.jobs__add {
    height: 30px;
    border-radius: 8px;
    font-size: 14px;
  }
.footer {
  background-color: #241239;
  padding: 100px 0;
  margin-top: 128px;
}
.footer div.container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 100px;
    color: #fff;
    font-size: 16px;
  }
.footer div.container img {
      max-width: 250px;
      height: 50px;
      object-fit: fill;
    }
.footer div.container p {
      padding: 0;
      margin: 0;
    }
.footer div.container ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
.footer div.container ul li {
        margin: 0;
        padding: 0;
      }
.footer div.container > div:first-child p {
        margin: 33px 0;
        line-height: 1.5;
      }
.footer div.container > div:nth-child(2) h2, .footer div.container > div:nth-child(3) h2 {
        margin-bottom: 36px;
        font-size: 20px;
      }
.footer div.container > div:nth-child(2) a, .footer div.container > div:nth-child(3) a {
        display: block;
        color: #fff;
        margin-bottom: 20px;
      }
.footer div.container .widget h2 {
        margin-bottom: 36px;
        font-size: 20px;
      }
.footer div.container .widget p {
        display: block;
        color: #fff;
        margin-bottom: 20px;
        font-size: 16px;
      }
.footer div.container > div:last-child p {
        margin: 20px 0;
      }
.footer div.container > div:last-child h4 {
        font-size: 18px;
      }
.footer div.container > div:last-child .social-links {
        display: flex;
        gap: 10px;
      }
.footer div.container > div:last-child .social-links a {
          border-radius: 5px;
          background-color: #efeef3;
          padding: 10px;
          margin-top: 10px;
          color: #000;
        }
.nav-buttons .login-btn {
    background-color: #fff;
    border: none;
    border-radius: 8px;
  }
.nav-buttons .login-btn h1 {
      color: #29d49c;
      font-size: 20px;
      text-align: center;
      margin: 0;
      padding: 10px 20px;
    }
.nav-buttons .signup-btn {
    background-color: #29d49c;

    border: 2px solid #29d49c;
    border-radius: 8px;
  }
.nav-buttons .signup-btn h1 {
      color: #fff;
      font-size: 20px;
      text-align: center;
      margin: 0;
      padding: 10px 20px;
    }
.nav-buttons .nav-login-btn {
    margin-left: 15px;
  }
.nav-buttons .nav-login-btn :hover {
      background-color: #f2f2f2;
      transition: 0.5s;
    }
.nav-buttons .nav-signup-btn {
    margin-left: 15px;
  }
.nav-buttons .nav-signup-btn :hover {
      background-color: #25bf8c;
      transition: 0.5s;
    }
@media (max-width: 1380px) {
  .nav-buttons {
    padding: 15px 25px;
  }

    .nav-buttons .nav-buttons__btn {
      padding: 5px 25px;
      font-weight: 600;
      border-radius: 8px;
      display: block;
      width: 100%;
    }

  .signup-btn {
    margin-top: 20px;
  }
}
.logged-in-profile-button {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  outline: none;
}
.logged-in-profile-button .image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
  }
.logged-in-profile-button .avatar {
    width: 50px;
    height: 50px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    margin-right: 15px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
.logged-in-profile-button .avatar svg {
      font-size: 30px;
    }
.logged-in-profile-button .name {
    color: #ffffff;
    margin: 0;
    font-weight: 600;
  }
.logged-in-profile-button .small-menu {
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    top: calc(100%);
    padding: 15px;
    list-style: none;
    width: 230px;
    right: 0;
    border-radius: 3px;
  }
.logged-in-profile-button .small-menu li {
      padding: 10px 5px;
      font-weight: 600;
      transition: all 0.15s linear;
    }
.logged-in-profile-button .small-menu li:hover {
        background-color: #f1f1f1;
      }
.logged-in-profile-button .small-menu li svg {
        margin-right: 15px;
        width: 20px;
      }
@media (max-width: 992px) {
.logged-in-profile-button .small-menu {
      position: relative;
      left: 0;
      flex: 0 0 100%;
      top: 30px;
      background-color: #000000;
      padding: 0
  }

      .logged-in-profile-button .small-menu li {
        padding: 10px 5px;
        font-weight: 600;
        transition: all 0.15s linear;
        color: #ffffff;
      }

        .logged-in-profile-button .small-menu li:hover {
          background-color: #000000;
        }

        .logged-in-profile-button .small-menu li svg {
          margin-right: 15px;
          width: 20px;
        }
    }
@media (max-width: 992px) {
.logged-in-profile-button {
    padding: 15px 15px 60px 15px
}
  }
.main-navigation {
  background-color: #fff;
  min-height: 100px;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1070;
  position: fixed;
  border-bottom: 1px solid #f2f2f2;
}
.main-navigation a {
    color: #8c8791;
  }
.main-navigation .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
.main-navigation .main-navigation__image {
    width: 190px;
    height: 72px;
    object-fit: contain;
  }
.main-navigation .main-nav__hamburger {
    display: none;
    margin-right: 150px;
  }
.main-navigation .main-nav__hamburger .line {
    width: 50px;
    height: 5px;
    background-color: #29d49c;
    display: block;
    margin: 8px 0 0 0;
    transition: all 0.1s ease-in-out;
  }
.main-navigation .main-navigation__buttons {
    display: flex;
    align-items: center;
  }
.main-navigation .main-nav__hamburger:hover {
    cursor: pointer;
  }
.main-navigation #main-nav__hamburger.is-active .line:nth-child(2) {
    opacity: 0;
  }
.main-navigation #main-nav__hamburger.is-active .line:nth-child(1) {
    transform: translateY(13px) rotate(45deg);
  }
.main-navigation #main-nav__hamburger.is-active .line:nth-child(3) {
    transform: translateY(-13px) rotate(-45deg);
  }
@media (max-width: 1400px) {
    .main-navigation .main-navigation__menu,
    .main-navigation .main-navigation__buttons {
      display: none;
    }

    .main-navigation .main-navigation__image {
    }

    .main-navigation .main-nav__hamburger {
      display: block;
      margin-right: 15px;
      margin-top: -5px;
    }
  }
@media (max-width: 480px) {
    .main-navigation .main-navigation__image {
      width: 180px;
      height: 70px;
      margin-left: 5px;
    }

    .main-navigation .main-nav__hamburger {
      display: block;
      margin-right: 15px;
    }
  }
@media (max-width: 350px) {
    .main-navigation .main-navigation__image {
      width: 140px;
      margin-left: 0;
    }

    .main-navigation .main-nav__hamburger {
      margin-right: 15px;
    }
  }
.nav-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}
.nav-menu .nav-menu__item .nav-menu__link {
      display: block;
      padding: 25px 20px;
      color: #454f63;
      text-decoration: none;
      transition: border-bottom 0.15s linear;
      font-size: 16px;
    }
.nav-menu .nav-menu__item :hover {
      color: #736e77;
    }
@media (max-width: 1380px) {
  .nav-menu {
    padding: 100px 0 15px;
    margin: 0;
    list-style: none;
    flex-direction: column;
  }
      .nav-menu .nav-menu__item .nav-menu__link {
        display: block;
        padding: 15px 25px;
        color: #ffffff;
        text-decoration: none;
        border-bottom: 5px solid transparent;
        transition: background-color 0.15s linear;
      }

        .nav-menu .nav-menu__item .nav-menu__link:hover {
          border-bottom-color: transparent;
        }
}
.nav_active {
  border-bottom: 1px solid #111;
}
.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  color: #000 !important;
  z-index: 1069;
  overflow-y: auto;
  -webkit-user-select: none;
          user-select: none;
}
.side-drawer .nav-menu {
    padding: 140px 15px 15px 15px;
  }
.side-drawer .nav-menu .nav-menu__item .nav-menu__link {
        color: #8c8791;
        font-size: 2.5em;
      }
.side-drawer .nav-menu .nav-menu__item .active {
        color: #454f63;
      }
.side-drawer .nav-buttons h1 {
      font-size: 35px;
      padding: 20px 40px;
    }
@media screen and (-webkit-min-device-pixel-ratio: 0) {
.side-drawer {
    height: 100%
}
  }
@media (min-width: 1380px) {
.side-drawer {
    display: none
}
  }
@media (max-width: 480px) {
    .side-drawer .nav-menu {
      padding: 105px 15px 15px 15px;
    }
      .side-drawer .nav-menu__item .nav-menu__link {
        padding: 15px 5px;
        font-size: 18px !important;
      }
    .side-drawer .nav-buttons {
      padding: 15px 5px 50px 5px;
    }
      .side-drawer .nav-buttons h1 {
        font-size: 18px;
        padding: 15px 35px;
      }
  }
.user-profile-menu{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.user-profile-menu img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 15px;
        object-fit: cover;
    }
.user-profile-menu .user-fullname{
        margin: 0;
        color: #ffffff;
        font-weight: 600;;
    }
.user-menu{
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    right: 15px;
    top: 100%;
}
.user-menu .btn-m{
        display: block;
        width: 100%;
        text-align: left;
        background-color: #ffffff;
        border: none;
        font-weight: 600;
        font-size: 14px;
        transition: all .15s linear;
        padding: 15px;
        text-decoration: none;
        color: #000000;
    }
.user-menu .btn-m svg{
            margin-right: 5px;
        }
.user-menu .btn-m:nth-child(1){
            border-bottom: 1px solid #000000;
        }
.user-menu .btn-m:hover{
            background-color: #f1f1f1;
        }
.search__results__title {
    color: rgba(120, 132, 158, 57%);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
.search__results__cards {
    height: 70vh;
    overflow-y: scroll;
    margin-top: 2rem;
  }
.notifications__title {
    color: rgba(120, 132, 158, 57%);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
.notifications__wrapper {
    display: grid;
    grid-template-columns: 380px 10px 1fr;
    gap: 40px;

    background-color: #fff;
    border: 1px solid #e6e8f0;
    border-radius: 12px;
    margin-top: 1rem;
    padding: 14px 37px;
  }
.notifications__wrapper hr {
      margin: 0;
      background-color: #e6e8f0;
      width: 1px;
      height: 100%;
    }
@media screen and (max-width: 1230px) {
    .notifications__wrapper {
      grid-template-columns: 1fr;
    }

      .notifications__wrapper hr {
        width: 100%;
        height: 1px;
      }

      .notifications__wrapper .notification__info {
        padding-bottom: 20px;
      }
}
@media screen and (max-width: 960px) {
      .notifications__wrapper .notification__list {
        height: 350px;
      }
}
.profile {
  border-radius: 12px;
  border: 1px solid #e6e8f0;
}
.profile_title {
    color: #78849e;
    font-size: 14px;
  }
.profile_layout {
    border-radius: 12px;
    border: 1px solid #e6e8f0;
  }
.profile_avatar {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
.profile_avatar .avatar_label {
      position: absolute;
      background-color: #00000059;
      color: #ffffff;
      border: 1px solid #555555;
    }
.job__new {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 13px;

  margin-top: 54px;
}
.job__new h2 {
    font-size: 16px;
    color: #454f63;
    margin-bottom: 0;
  }
.job__new button {
    padding: 18px;
    border-radius: 12px;
    line-height: 1;
  }
.single__job__title {
    color: #78849e;
    font-size: 12px;
    font-weight: bold;
    opacity: 0.57;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
.single__job__card {
    position: relative;
    width: 100%;
    min-height: 100px;
    border: 1px solid #e6e8f0;
    border-radius: 12px;
    overflow: hidden;
  }
.single__job__card__edit {
      position: absolute;
      top: 30px;
      right: 30px;
      z-index: 1;
      color: #fff;
      font-size: 30px;
      background-color: transparent;
      border: none;
    }
.single__job__card__add {
      position: absolute;
      top: 30px;
      right: 90px;
      z-index: 1;
      color: #fff;
      font-size: 30px;
      background-color: transparent;
      border: none;
    }
.single__job__card .job__banner {
      position: relative;
      width: 100%;
      height: 180px;
      aspect-ratio: 16 / 100;
    }
.single__job__card .job__banner img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
.single__job__card .job__banner__overlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: transparent
          linear-gradient(179deg, #00000045 0%, #f7f7fa 100%) 0% 0% no-repeat
          padding-box;
      }
.single__job__card .job__details {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      column-gap: 50px;
      padding: 25px 10px 25px 50px;
    }
.single__job__card .job__details__actions {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        margin-top: 43px;
      }
.single__job__card .job__details__actions button {
          width: 164px;
          border-radius: 12px;
          border-width: 2px;
        }
.single__job__card .job__details .job__details__row:first-child .job__title {
          color: #454f63;
          font-size: 32px;
          margin-top: 25px;
        }
.single__job__card .job__details .job__details__row:first-child .job__body {
          margin-top: 21px;
        }
.single__job__card .job__details .job__details__row:first-child .job__body h4 {
            font-size: 16px;
            color: #78849e;
          }
.single__job__card .job__details .job__details__row:first-child .job__body h4 span {
              padding-left: 12px;
              color: #454f63;
            }
.single__job__card .job__details .job__details__row:first-child .job__date {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
          margin: 27px 0;
        }
.single__job__card .job__details .job__details__row:first-child .job__date__start,
          .single__job__card .job__details .job__details__row:first-child .job__date__expire {
            background-color: #78849e29;
            border-radius: 8px;
            padding: 10px 35px 2px 35px;
          }
.single__job__card .job__details .job__details__row:first-child .job__date__start h4, .single__job__card .job__details .job__details__row:first-child .job__date__expire h4 {
              font-size: 17px;
              color: #454f63;
            }
.single__job__card .job__details .job__details__row:first-child .job__description p {
            color: #78849e;
          }
.single__job__card .job__details .job__details__row:last-child {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 100px;

        height: 550px;
        overflow-y: scroll;
      }
.single__job__card .job__details .job__details__row:last-child h4 {
          color: #78849e;
          font-size: 12px;
          font-weight: bold;
        }
.single__job__card .job__details .job__details__row:last-child .crew__list {
          margin: 0;
          padding: 0;
        }
.single__job__card .job__details .job__details__row:last-child .crew__list__item {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 15px 0;
          }
.single__job__card .job__details .job__details__row:last-child .crew__list__item:not(:last-child) {
              border-bottom: 1px solid #e6e8f0;
            }
.single__job__card .job__details .job__details__row:last-child .crew__list__item img {
              width: 48px;
              height: 48px;
              object-fit: cover;
              border-radius: 12px;
            }
.single__job__card .job__details .job__details__row:last-child .crew__list__item div p {
                margin: 0;
                color: #454f63;
              }
.single__job__card .job__details .job__details__row:last-child .crew__list__item div span {
                font-size: 14px;
                color: #454f63;
              }
.job__description h1, .job__description h2, .job__description h3, .job__description h4, .job__description h5, .job__description h6, .job__description p, .job__description span, .job__description ul, .job__description ol, .job__description li {
    line-height: normal !important;
    color: #454f63 !important;
  }
.job__description span{
    background-color: transparent !important;
    display: inline-block !important;
    overflow: hidden !important;
    word-break: break-all !important;
    color: #454f63 !important;
    font-family: inherit !important;
  }
.job__description p{
    display: block !important;
    overflow: hidden !important;
    word-break: break-all !important;
    color: #454f63 !important;
    font-family: inherit !important;
  }
.job__description ol, .job__description ul{
    margin-left: 18px;
    color: #454f63 !important;
    font-family: inherit !important;
  }
#login {
  max-width: 500px;
  margin: auto;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
#login {
    padding-bottom: 40px
}
  }
.form-details {
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .login-title {
    font-size: 35px;
  }
}
@media (max-width: 320px) {
  .form-remember,
  .forgot-password-link {
    font-size: 12px !important;
  }
}
.about-image {
  display: block;
  margin: 0 auto;
  width: auto;
}
.about-signup-with-worqman {
  display: flex;
  align-items: stretch;
  margin-top: 90px;
  padding: 0 15px;
}
.content-box_about {
  padding: 0 45px;
  text-align: left;
}
.content-box_about .about_title {
    font-size: 40px;
    margin-bottom: 60px;
    font-weight: 600;
    position: relative;
    color: #78849e;
    text-transform: uppercase;
  }
.content-box_about .about_title::after {
      content: "";
      width: 126px;
      height: 12px;
      position: absolute;
      left: 0;
      bottom: -35px;
      background-color: #78849e;
    }
.content-box_about .content-box__children {
    margin-top: 45px;
  }
.content-box__center {
  text-align: center;
}
.content-box__center .about_title {
    margin: 0 auto 60px;
  }
.content-box__center .about_title::after {
      left: calc(50%);
      transform: translateX(-50%);
    }
.content-box__center .about_description {
    margin: 0 auto 60px;
    color: #78849e;
    font-size: 18px;
  }
.about_description.desc-gray {
  font-size: 18px;
  color: #78849e;
}
@media (max-width: 992px) {
  .about-signup-with-worqman {
    flex-direction: column;
  }
  .content-box_about {
    margin-top: 35px;
    padding: 0 20px;
  }
}
@media (max-width: 768px) {
  .about-image {
    width: 95%;
    margin: auto;
  }
  .about-signup-with-worqman {
    flex-direction: column;
    margin-top: 0;
    padding: 0;
  }
  .content-box_about {
    margin-top: 35px;
    padding: 0 20px;
  }
}
@media (max-width: 360px) {
  .content-box_about,
  .about_title {
    font-size: 30px !important;
    max-width: 100%;
  }
  .about_description {
    font-size: 14px !important;
  }
  .about-signup-with-worqman {
    margin-top: -60px;
  }
  .about-image {
    width: 100%;
    margin: auto;
  }
  .content-box_about {
    padding: 0;
  }
}
.why-worqman-content {
  text-align: left;
  display: flex;
  align-items: center;
}
.icon--left--text {
  color: #78849e;
  margin: auto;
  padding: 16px 0 !important;
}
.for-companies-content {
  padding: 0;
}
.content-box_for-companies {
  padding: 0;
  text-align: left;
}
.content-box_for-companies .for-companies-title {
    font-size: 40px;
    margin-bottom: 60px;
    font-weight: 600;
    position: relative;
    color: #78849e;
    text-transform: uppercase;
  }
.content-box_for-companies .for-companies-title::after {
      content: "";
      width: 126px;
      height: 12px;
      position: absolute;
      left: 0;
      bottom: -35px;
      background-color: #78849e;
    }
.content-box_for-companies .content-box__children {
    margin-top: 45px;
  }
.content-box__center {
  text-align: center;
}
.content-box__center .for-companies-title {
    margin: 0 auto 60px;
  }
.content-box__center .for-companies-title::after {
      left: calc(50%);
      transform: translateX(-50%);
    }
.content-box__center .for-companies-description {
    margin: 0 auto 60px;
    color: #78849e;
    font-size: 25px;
  }
.for-companies-description.desc-gray {
  font-size: 25px;
  color: #78849e;
}
@media (max-width: 992px) {
  .content-box_for-companies {
    margin-top: 35px;
    padding: 0;
  }
  .for-companies-text-container {
    padding: 0;
  }
  .for-comp-images,
  .for-comp-double-images {
    padding: 0;
  }
  .for-comp-image-1 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .for-comp-image-2 {
    padding-left: 0 !important;
  }
  .for-comp-image-3 {
    padding-right: 0 !important;
  }
}
@media (max-width: 768px) {
  .content-box_for-companies {
    margin-top: 35px;
  }
  .for-companies-text-wrapper {
    padding: 0;
  }
}
@media (max-width: 360px) {
  .content-box_for-companies,
  .for-companies-title {
    font-size: 30px !important;
    max-width: 100%;
  }
  .for-companies-description {
    font-size: 18px !important;
  }
}
#for-crew .for-crew-button .upload-button {
      padding: 15px 30px;
      font-weight: 600;
      display: flex;
      align-items: center;
      border-radius: 5px;
      background-color: #ffffff;
      border: 2px solid #ffffff;
      font-size: 18px;
      transition: all 0.15s linear;
      color: #000000;
      text-decoration: none;
    }
#for-crew .for-crew-button .upload-button svg {
        margin-right: 15px;
      }
#for-crew .for-crew-button .upload-button:hover {
        background-color: #9e9898;
        border-color: #9e9898;
      }
#signup-message .confirmation-head{
        text-align: center;

    }
#signup-message .confirmation-head .confirmation-head__image{
            margin-top: 30px;
            margin-bottom: 30px;
        }
#signup-message .confirmation-head .confirmation-head__title{
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 30px;
        }
#signup-message .confirmation-head .confirmation-head__description{
            color: #9E9D9D;
            margin-bottom: 5px;
        }
#signup-message .confirmation-head .confirmation-head__email{
            font-weight: 600;
        }
#signup-message .confirmation-head .confirmation-head__description-2{
            color: #9E9D9D;
            margin-bottom: 5px;
        }
.signup-crew {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 40px 0;
  max-width: 500px;
}
@media (max-width: 768px) {
.signup-crew {
    padding-bottom: 40px
}
  }
#signup-company {
  max-width: 500px;
  margin: auto;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
#signup-company {
    padding-bottom: 40px
}
  }
#signup {
  max-width: 1000px;
  margin: auto;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.signup-as-a-company,
.signup-as-a-crew {
  border: 2px solid #241239;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 50px;
  filter: gray;
  filter: grayscale(1);
}
.signup-as-a-company:hover, .signup-as-a-crew:hover {
    filter: none;
    border: 2px solid #29d49c;
  }
.signup-as-a-company svg, .signup-as-a-crew svg {
    width: 120px !important;
    height: 120px !important;
    margin-bottom: 16px;
  }
.signup-as-a-company svg#Layer_1, .signup-as-a-crew svg#Layer_1 {
    width: 100px;
    height: 100px;
    margin-bottom: 200px;
  }
@media (max-width: 768px) {
  .signup-title {
    font-size: 35px;
  }
}
#homepage {
  width: 100%;
  height: 100vh;
}
#homepage .banner .banner__info h1 {
        font-size: 45px;
      }
@media (max-width: 960px) {
#homepage .banner .banner__info h1 {
          font-size: 25px
      }
        }
#homepage .banner .banner__info p {
        font-size: 18px;
        max-width: 550px;
      }
@media (max-width: 960px) {
#homepage .banner .banner__info p {
          font-size: 16px
      }
        }
#homepage .intro-section-wrapper {
    margin-top: 244px;
    display: flex;
    justify-content: space-between;
  }
@media (max-width: 960px) {
#homepage .intro-section-wrapper {
      flex-direction: column
  }
    }
#homepage .intro-section-wrapper .image-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#homepage .intro-section-wrapper .image-wrapper {
        width: 100%
    }
      }
#homepage .intro-section-wrapper .image-wrapper img {
        width: 100%;
      }
#homepage .intro-section-wrapper .content-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#homepage .intro-section-wrapper .content-wrapper {
        width: 100%;
        margin-top: 30px
    }
      }
#homepage .intro-section-wrapper .content-wrapper h2 {
        font-size: 30px;
        color: #16133e;
        margin-bottom: 40px;
        line-height: 1.3;
      }
@media (max-width: 960px) {
#homepage .intro-section-wrapper .content-wrapper h2 {
          font-size: 25px;
          text-align: center
      }
        }
#homepage .intro-section-wrapper .content-wrapper p {
        font-size: 22px;
        line-height: 1.3;
        color: #5a7184;
      }
@media (max-width: 960px) {
#homepage .intro-section-wrapper .content-wrapper p {
          font-size: 16px;
          text-align: center
      }
        }
#homepage .grow-team-wrapper {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
  }
@media (max-width: 960px) {
#homepage .grow-team-wrapper {
      flex-direction: column
  }
    }
#homepage .grow-team-wrapper .image-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#homepage .grow-team-wrapper .image-wrapper {
        width: 100%
    }
      }
#homepage .grow-team-wrapper .image-wrapper img {
        width: 100%;
      }
#homepage .grow-team-wrapper .content-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#homepage .grow-team-wrapper .content-wrapper {
        width: 100%;
        margin-top: 30px
    }
      }
#homepage .grow-team-wrapper .content-wrapper h2 {
        font-size: 30px;
        color: #16133e;
        margin-bottom: 40px;
        line-height: 1.3;
      }
@media (max-width: 960px) {
#homepage .grow-team-wrapper .content-wrapper h2 {
          font-size: 25px;
          text-align: center
      }
        }
#homepage .grow-team-wrapper .content-wrapper p {
        font-size: 22px;
        line-height: 1.3;
        color: #5a7184;
      }
@media (max-width: 960px) {
#homepage .grow-team-wrapper .content-wrapper p {
          font-size: 16px;
          text-align: center
      }
        }
#homepage .about-worqers-wrapper {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
  }
@media (max-width: 960px) {
#homepage .about-worqers-wrapper {
      flex-direction: column
  }
    }
#homepage .about-worqers-wrapper .image-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#homepage .about-worqers-wrapper .image-wrapper {
        width: 100%
    }
      }
#homepage .about-worqers-wrapper .image-wrapper img {
        width: 100%;
      }
#homepage .about-worqers-wrapper .content-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#homepage .about-worqers-wrapper .content-wrapper {
        width: 100%;
        margin-top: 30px
    }
      }
#homepage .about-worqers-wrapper .content-wrapper h2 {
        font-size: 30px;
        color: #16133e;
        margin-bottom: 40px;
        line-height: 1.3;
      }
@media (max-width: 960px) {
#homepage .about-worqers-wrapper .content-wrapper h2 {
          font-size: 25px;
          text-align: center
      }
        }
#homepage .about-worqers-wrapper .content-wrapper p {
        font-size: 22px;
        line-height: 1.3;
        color: #5a7184;
      }
@media (max-width: 960px) {
#homepage .about-worqers-wrapper .content-wrapper p {
          font-size: 16px;
          text-align: center
      }
        }
@media (max-width: 960px) {
    }
@media (max-width: 992px) {
    #homepage .sector-items-list {
      margin-top: 60px;
    }
  }
#homepage #choose-sector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
  }
#homepage .browser-hundreeds-of-professionals-wrapper {
    background-color: #f8f8f8;
    height: 515px;
  }
#homepage .browser-hundreeds-of-professionals-container {
    max-width: 1200px;
    height: 500px;
    margin: auto;
    padding: 15px 0 0 0;
  }
#homepage .bhop-text {
    padding: 0 15px 0 50px;
  }
#homepage .background-image {
    background: url("https://worqman.fra1.digitaloceanspaces.com/images/new-phone.png")
      no-repeat;
    background-position: right;
    background-size: auto 100%;
    height: 500px;
  }
@media (max-width: 992px) {
#homepage .background-image {
      display: none
  }
    }
@media (max-width: 992px) {
    #homepage .browser-hundreeds-of-professionals-wrapper {
      height: auto;
    }
    #homepage .browser-hundreeds-of-professionals-container {
      height: auto;
      padding: 15px;
    }
  }
/*
  @media (max-width: 600px) {
    .browser-hundreeds-of-professionals-wrapper {
      margin-bottom: 320px;
    }
  }
*/
@media (max-width: 500px) {
    #homepage .bhop-text {
      padding: 0 15px;
    }
  }
@media (max-width: 600px) {
#choose-sector-container {
    padding: 0 !important
}
  }
#choose-sector {
  margin-top: 50px;
}
#choose-sector .choose-sector-btn-link {
    color: #fff;
    text-decoration: none;
  }
#choose-sector .choose-sector-btn {
    background-color: #29d49c;
    padding: 15px;
    color: #fff;
    border: 0;
    font-size: 23px;
    border-radius: 8px;
  }
#choose-sector .choose-sector-btn:hover {
      background-color: blue;
      background-color: #25bf8c;
      transition: 0.5s;
    }
@media (max-width: 350px) {
#choose-sector .choose-sector-btn {
      font-size: 16px
  }
    }
#post-your-projects {
  margin-top: 50px;
}
#post-your-projects .post-your-projects-btn-link {
    color: #fff;
    text-decoration: none;
  }
#post-your-projects .post-your-projects-btn {
    background-color: #29d49c;
    padding: 15px;
    color: #fff;
    border: 0;
    font-size: 23px;
    border-radius: 8px;
  }
#post-your-projects .post-your-projects-btn:hover {
      background-color: blue;
      background-color: #25bf8c;
      transition: 0.5s;
    }
@media (max-width: 360px) {
#post-your-projects .post-your-projects-btn {
      font-size: 16px
  }
    }
@media (max-width: 768px) {
#post-your-projects {
    margin-top: 6px !important
}
  }
.filter {
  display: flex;
  justify-content: center;
}
.filter .page__job_search {
    position: absolute;
    width: 80%;
    margin-top: -120px;
    background-color: #fff;
    padding: 70px 40px;
    border-radius: 20px;
    box-shadow: 0 1px 12px 1px #888;
    z-index: 4;
  }
@media (max-width: 960px) {
.filter .page__job_search {
      width: 90%
  }
    }
.error {
  height: 50vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 50px;
  color: #454f63;
}
.error h1 {
    font-size: 50px;
  }
.error h2 {
    font-size: 35px;
  }
.contact-wrapper {
  position: relative;
  background: #fff;
  max-width: 1200px;
  margin: auto;
  border-radius: 8px;
  margin-top: -180px;
  display: flex;
  padding: 60px 80px;
}
.contact-contents {
  display: flex;
}
.contact-left {
  padding-right: 50px;
}
.online-support-only {
  color: #78849e;
}
.online-support-only h1 {
    font-size: 35px;
    margin-bottom: 20px;
  }
.office-hours {
  margin-top: 35px;
  color: #78849e;
}
.office-hours h1 {
    font-size: 35px;
    margin-bottom: 20px;
  }
.office-hours p {
    margin-bottom: 6px;
  }
.office-hours ul {
    padding-left: 18px;
  }
.form--data {
  padding: 0 !important;
}
.error-text {
  font-size: 14px;
}
.contact-btn-wrapper {
  padding: 0 5px;
}
.contact-btn {
  border: 0;
  border-radius: 8px;
  background-color: #2bd49c;
  color: #fff;
  padding: 14px 0;
  font-weight: 600;
}
.contact-btn:hover {
    background-color: #25bf8c;
    transition: 0.5s;
  }
@media (max-width: 1250px) {
  .contact-wrapper {
    position: relative;
    margin: 20px;
    margin-top: -180px;
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .contact-contents {
    flex-direction: column;
  }
  .contact-left {
    padding: 0;
  }
  .contact-form {
    padding: 0;
    margin-top: 22px;
  }
  .form--group {
    padding: 6px 0 !important;
  }
  .contact-btn-wrapper {
    padding: 0;
  }
}
@media (max-width: 500px) {
  .contact-wrapper {
    padding: 30px 15px;
    margin-left: 0;
    margin-right: 0;
    margin-top: -260px;
  }
    .online-support-only h1, .office-hours h1 {
      font-size: 25px;
      margin-bottom: 15px;
    }
    .online-support-only p, .office-hours p {
      font-size: 14px;
    }
    .online-support-only ul, .office-hours ul {
      font-size: 14px;
    }
  .form--control,
  .contact-btn {
    font-size: 14px !important;
  }
}
.banner .container {
    height: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 15px;
  }
.banner .container .row {
      height: 100%;
    }
.banner .container .row .banner-children {
        padding: 60px 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
      }
.banner .container .row .banner-children .title {
          font-size: 45px;
          color: #ffffff;
          font-weight: 600;
        }
.banner .container .row .banner-children .description {
          font-size: 25px;
          color: #ffffff;
          margin-bottom: 75px;
        }
@media (max-width: 992px) {
.banner {
    display: table;
    width: 100%
}
  }
@media (max-width: 580px) {
  .title {
    padding: 0 !important;
    font-size: 42px !important;
  }
  .description {
    padding: 0 !important;
    font-size: 22px !important;
    margin-bottom: 50px !important;
  }
}
.sidebar .error-text {
    color: red;
    margin-top: 5px;
  }
.sidebar .subscribe-message {
    color: green;
  }
.sidebar--title {
    font-weight: 600;
    font-size: 20px;
  }
.sidebar .search--input--wrapper {
    position: relative;
    margin-bottom: 30px;
  }
.sidebar .search--input--wrapper svg {
      position: absolute;
      top: 15px;
      left: 10px;
    }
.sidebar .search--input--wrapper input {
      width: 100%;
      padding: 10px 15px 10px 35px;
      border: 1px solid #dfdfdf;
    }
.sidebar .categories--list {
    list-style: none;
    padding-left: 15px;
  }
.sidebar .categories--list li a {
        font-weight: 600;
        color: #000000;
        font-size: 18px;
        text-decoration: underline;
        display: block;
        padding-bottom: 3px;
      }
.sidebar .post-list {
    margin-bottom: 30px;
  }
.sidebar .post-list .post {
      display: flex;
      margin-bottom: 15px;
    }
.sidebar .post-list .post .image {
        flex: 0 0 100px;
        height: 80px;
      }
.sidebar .post-list .post .image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
.sidebar .post-list .post .data {
        padding: 0 15px;
      }
.sidebar .post-list .post .data a {
          color: #000000;
          font-weight: 600;
        }
.sidebar .post-list .post .data p {
          color: #9e9d9d;
        }
.sidebar .subscribe {
    background-color: #2bd49c;
    padding: 30px 15px;
  }
.sidebar .subscribe h6 {
      color: #ffffff;
      margin-bottom: 15px;
      font-size: 18px;
    }
.sidebar .subscribe label {
      color: #fff;
    }
.sidebar .subscribe .subscribe--input--wrapper {
      position: relative;
      margin-bottom: 15px;
    }
.sidebar .subscribe .subscribe--input--wrapper svg {
        position: absolute;
        top: 15px;
        right: 10px;
      }
.sidebar .subscribe .subscribe--input--wrapper input {
        width: 100%;
        padding: 10px 35px 10px 15px;
        border: 1px solid #dfdfdf;
      }
.sidebar .subscribe .check-box {
      display: flex;
      align-items: flex-start;
      margin: 0;
      color: #9e9d9d;
    }
.sidebar .subscribe .check-box input {
        margin-right: 5px;
        margin-top: 5px;
      }
.banner {
  height: 450px;
}
.blog--card {
  text-align: center;
  border-bottom: 1px solid #9a9a9a;
  margin-bottom: 30px;
}
.blog--card img {
    height: 400px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
.blog--card h4 {
    padding: 30px 0 15px 0;
    text-align: center;
    font: bold 27px/45px Poppins;
    color: #000;
  }
.blog--card p {
    font-size: 18px;
    letter-spacing: 0;
    color: #9e9d9d;
    max-width: 700px;
    margin: 0 auto;
  }
.blog--card a {
    text-align: center;
    text-decoration: underline;
    font: bold 20px/45px Poppins;
    color: #000000;
    padding: 15px 0 30px 0;
    display: block;
  }
.blog--card .blog--post--content {
    min-height: 50px;
    overflow: hidden;
  }
.post--content {
  max-width: 770px;
}
.sidebar--media h4 {
    font-size: 16px;
  }
.sidebar--media a {
    color: black;
    font-size: 14px;
  }
.main-title-categories {
  display: flex;
  margin: 0;
  align-items: center;
}
.main-title-categories svg {
    border: 1.5px solid #dfdfdf;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    text-align: center;
    padding: 8px;
    margin-right: 15px;
  }
.main-title-categories span {
    font-size: 40px;
    font-weight: 600;
  }
.blog--carda {
  max-height: 450px;
  height: 450px;
  border: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.blog--carda img {
    height: 250px;
    object-fit: cover;
    width: 100%;
    object-position: top;
  }
.blog--carda h4 {
    font-size: 18px;
  }
.blog--carda a {
    font-size: 15px;
    text-align: center;
    color: black;
    text-decoration: underline;
    padding-bottom: 10px;
    font-weight: 600;
  }
.wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    position:relative;


}
.wrapper .loading{
        width:100%;
        height: 100%;
        position: absolute;
    }
.wrapper .subscription_wrapper {
        width:100%;
        max-width: 337px;
        padding: 40px;
        background-color: #fff;
        border-radius: 12px;
    
    }
.wrapper .subscription_wrapper .main-title {
        color: #16133e;
        font-size: 38px;
      }
.wrapper .subscription_wrapper .main-text {
        color: #5a7184;
        font-size: 17px;
        padding-top: 13px;
      }
.wrapper .subscription_wrapper .sub-text {
        color: #5a7184;
        font-size: 14px;
        padding-top: 13px;
      }
.wrapper .subscription_wrapper .main-price {
        color: #16133e;
        font-size: 38px;

    }
.wrapper .subscription_wrapper .packageItem{
        display:flex;
        align-items: center;
    }
.wrapper .subscription_wrapper .packageItem svg {
            width: 100%;
            max-width:26px ;
            height: auto;
            color:#2BD49C;
        }
.wrapper .subscription_wrapper .packageItem p{
            padding-top: 14px;
            padding-left: 12px;
            color: #5A7184
            
        }
.wrapper .subscription_wrapper .packageItem .underline{
            text-decoration: underline
        }
.wrapper .subscription_wrapper button{
        margin-top: 40px;
        border-radius: 12px;
        width: 100%;
    }
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}
.wrapper .loading {
    width: 100%;
    height: 100%;
    position: absolute;
  }
.wrapper .success_wrapper {
    width: 100%;
    max-width: 800px;
    padding: 40px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
  }
.wrapper .success_wrapper .main-title {
      color: #16133e;
      font-size: 38px;
    }
.wrapper .success_wrapper .main-text {
      color: #5a7184;
      font-size: 17px;
      padding-top: 13px;
    }
.wrapper .success_wrapper button {
      margin-top: 40px;
      border-radius: 12px;
    }
.subscription {
  margin-top: 60px !important;
}
@media (max-width: 960px) {
.subscription.row {
      width: 100%;
      display: flex;
      flex-direction: column
  }
    }
.subscription .wrapper {
    margin-top: 20px;
    background-color: #fff;
    width: 100%;
    border-radius: 12px;
    padding: 40px;
    display: block;
  }
.subscription .wrapper p {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
@media (max-width: 600px) {
.subscription .wrapper p {
        font-size: 12px !important
    }
      }
.subscription .wrapper a:hover svg{
          fill:"#2bd49c"; 
        }
.subscription .wrapper svg{
      fill:"#2bd49c"; 
    }
.subscription .subscription_wrapper {
    margin-bottom: 30px !important;
  }
@media (max-width: 960px) {
.subscription .subscription_wrapper.col-6 {
        max-width: 100% !important
    }
      }
.subscription .title {
    color: rgba(120, 132, 158, 57%);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 15px;
  }
@media (max-width: 600px) {
.subscription .title {
      font-size: 10px !important
  }
    }
.subscription span.title {
    color: rgba(120, 132, 158, 57%);
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
@media (max-width: 600px) {
.subscription span.title {
      font-size: 12px !important
  }
    }
.subscription__cardWrapper {
    margin: 26px 0 100px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 18px;
  }
.pricing-banner {
  background-color: #caebe0;
  min-height: 400px;
  display: flex;
  align-items: center;
  opacity: 0.5;
}
.pricing-banner img {
    width: 100%;
  }
@media (max-width: 960px) {
.pricing-banner {
    margin-top: 25px;

    padding-top: 30px
}
  }
.pricing-banner .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
@media (max-width: 960px) {
.pricing-banner .container {
      justify-content: center
  }
    }
.pricing-banner h1 {
    color: #241239;
    font-size: 74px;
    font-weight: bold;
    margin-bottom: 30px;
  }
@media (max-width: 960px) {
.pricing-banner h1 {
      font-size: 30px;
      text-align: center
  }
    }
.pricing-banner svg {
    width: 25px;
    color: #2bd49c;
    margin-right: 5px;
  }
.pricing-banner .banner-description .list-items {
      display: flex;
      align-items: center;
    }
@media (max-width: 960px) {
.pricing-banner .banner-description .list-items {
        text-align: center;
        flex-wrap: wrap;
        justify-content: center
    }
      }
.pricing-banner .banner-description .list-items span {
        font-size: 16px;
        margin-right: 15px;
      }
@media (max-width: 960px) {
.pricing-banner .banner-description .list-items span {
          margin-bottom: 15px
      }
        }
.package-plan {
  background-color: #f7f7fa;
  padding-top: 60px;
  padding-bottom: 80px;
}
.package-plan .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
.hire-crew {
  margin: 80px 0px;
  min-height: 300px;
  position: relative;
}
.hire-crew .hire-crew-content {
    padding: 70px;
    background-size: 880px;
    background-position: top right;
    background-repeat: no-repeat;
    border-radius: 10px;
    position: relative;
  }
@media (max-width: 960px) {
.hire-crew .hire-crew-content {
      padding: 40px;
      background-position: top right;
      background-size: cover
  }
    }
.hire-crew .hire-crew-content::before {
      content: "";
      position: absolute;
      top: 0;
      width: 70%;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        270deg,
        hsla(0, 0%, 100%, 0),
        #24bc89,
        #24bc89
      );
      opacity: 1;
      overflow: hidden;
      z-index: 8;
      border-radius: 10px;
    }
@media (max-width: 500px) {
.hire-crew .hire-crew-content::before {
        opacity: 0.8
    }
      }
.hire-crew h2 {
    font-size: 38px;
    color: #fff;
    margin-bottom: 20px;
    max-width: 320px;
    line-height: 1.49;
    z-index: 9;
    position: relative;
  }
@media (max-width: 960px) {
.hire-crew h2 {
      font-size: 25px
  }
    }
.hire-crew p {
    font-size: 18px;
    max-width: 530px;
    color: #fff;
    line-height: 2.28;
    z-index: 9;
    position: relative;
  }
@media (max-width: 960px) {
.hire-crew p {
      font-size: 16px
  }
    }
.pricing-testimonials {
  margin-top: 80px;
}
.pricing-testimonials h2 {
    color: #16133e;
    font-size: 38px;
    text-align: center;
    margin-bottom: 15px;
  }
.pricing-testimonials p {
    color: #5a7184;
    font-size: 18px;
    text-align: center;
  }
.pricing-testimonials .testimonial-wrapper {
    margin-top: 60px;
    display: flex;
    align-items: center;
  }
@media (max-width: 960px) {
.pricing-testimonials .testimonial-wrapper {
      flex-direction: column
  }
    }
.pricing-testimonials .testimonial-wrapper img {
      max-width: 450px;
      max-height: 268px;
      object-fit: cover;
      border-radius: 10px;
      width: 100%;
      height: 100%;
    }
.pricing-testimonials .testimonial-wrapper .what-they-say {
      padding: 70px;
    }
@media (max-width: 960px) {
.pricing-testimonials .testimonial-wrapper .what-they-say {
        padding: 20px
    }
      }
.pricing-testimonials .testimonial-wrapper .what-they-say h4 {
        font-size: 29px;
        font-weight: bold;
        color: #000000;
        line-height: 1.35;
        margin-bottom: 20px;
      }
@media (max-width: 960px) {
.pricing-testimonials .testimonial-wrapper .what-they-say h4 {
          font-size: 20px
      }
        }
.pricing-testimonials .testimonial-wrapper .what-they-say span {
        color: #454f63;
        font-size: 18px;
      }
.gdpr-wrapper {
  margin-top: 80px;
}
.gdpr-wrapper .container {
    background-color: #f7f7fa;
    border-radius: 10px;
    padding: 25px 40px;
    display: flex;
    align-items: flex-start;
  }
@media (max-width: 500px) {
.gdpr-wrapper .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center
  }
    }
.gdpr-wrapper img {
    width: 100%;
    max-width: 80px;
  }
.gdpr-wrapper .gdpr-content {
    margin-left: 35px;
  }
@media (max-width: 500px) {
.gdpr-wrapper .gdpr-content {
      margin: 0
  }
    }
.gdpr-wrapper .gdpr-content h2 {
      font-size: 30px;
      margin-top: 0;
      margin-bottom: 15px;
      color: #16133e;
    }
@media (max-width: 600px) {
.gdpr-wrapper .gdpr-content h2 {
        font-size: 20px
    }
      }
@media (max-width: 500px) {
.gdpr-wrapper .gdpr-content h2 {
        margin-top: 30px
    }
      }
.gdpr-wrapper .gdpr-content p {
      font-size: 18px;
      color: #5a7184;
    }
@media (max-width: 600px) {
.gdpr-wrapper .gdpr-content p {
        font-size: 16px
    }
      }
#hire .hire-banner {
    background-color: #caebe0;
    max-height: inherit;
    padding-top: 30px;
  }
@media (max-width: 960px) {
#hire .hire-banner {
      margin-top: 25px;
      padding-top: 30px
  }
    }
#hire .hire-banner .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
@media (max-width: 960px) {
#hire .hire-banner .container {
        flex-direction: column;
        justify-content: center
    }
      }
#hire .hire-banner .content-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#hire .hire-banner .content-wrapper {
        width: 100%;
        text-align: center
    }
      }
#hire .hire-banner .content-wrapper h2 {
        font-size: 30px;
        margin-bottom: 30px;
      }
@media (max-width: 960px) {
#hire .hire-banner .content-wrapper h2 {
          font-size: 25px
      }
        }
#hire .hire-banner .content-wrapper p {
        font-size: 18px;
        margin-bottom: 30px;
      }
@media (max-width: 960px) {
#hire .hire-banner .content-wrapper p {
          font-size: 16px
      }
        }
#hire .hire-banner .content-wrapper a {
        text-decoration: none;
      }
@media (max-width: 960px) {
#hire .hire-banner .content-wrapper a button {
            margin: 0 auto
        }
          }
#hire .hire-banner .content-wrapper .btn {
        padding: 12px 32px;
      }
#hire .hire-banner .content-wrapper .btn:hover {
          text-decoration: none;
        }
#hire .hire-banner .image-wrapper {
      padding-top: 60px;
      width: calc(50% - 20px);
      text-align: right;
    }
@media (max-width: 960px) {
#hire .hire-banner .image-wrapper {
        width: 100%;
        text-align: center
    }
      }
#hire .hire-banner .image-wrapper img {
        width: 100%;
        max-width: 280px;
      }
@media (max-width: 960px) {
#hire .hire-banner .image-wrapper img {
          max-width: 200px
      }
        }
#hire .secondary-background {
    max-height: inherit;
    min-height: auto;
  }
@media (max-width: 960px) {
#hire .secondary-background {
      min-height: 450px;
      max-height: inherit
  }
    }
#hire .secondary-background .banner__info {
      max-width: 50%;
    }
@media (max-width: 960px) {
#hire .secondary-background .banner__info {
        max-width: 100%
    }
      }
#hire .secondary-background h1 {
      font-size: 35px;
    }
@media (max-width: 960px) {
#hire .secondary-background h1 {
        font-size: 25px
    }
      }
#hire .hire-worqers-wrapper {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
  }
@media (max-width: 960px) {
#hire .hire-worqers-wrapper {
      flex-direction: column
  }
    }
#hire .hire-worqers-wrapper .image-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#hire .hire-worqers-wrapper .image-wrapper {
        width: 100%
    }
      }
#hire .hire-worqers-wrapper .image-wrapper img {
        width: 100%;
      }
#hire .hire-worqers-wrapper .content-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#hire .hire-worqers-wrapper .content-wrapper {
        width: 100%;
        margin-top: 30px
    }
      }
#hire .hire-worqers-wrapper .content-wrapper h2 {
        font-size: 30px;
        color: #16133e;
        margin-bottom: 40px;
        line-height: 1.3;
      }
@media (max-width: 960px) {
#hire .hire-worqers-wrapper .content-wrapper h2 {
          font-size: 25px;
          text-align: center
      }
        }
#hire .hire-worqers-wrapper .content-wrapper p {
        font-size: 22px;
        line-height: 1.3;
        color: #5a7184;
      }
@media (max-width: 960px) {
#hire .hire-worqers-wrapper .content-wrapper p {
          font-size: 16px;
          text-align: center
      }
        }
#hire .find-and-hire-wrapper {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
  }
@media (max-width: 960px) {
#hire .find-and-hire-wrapper {
      flex-direction: column
  }
    }
#hire .find-and-hire-wrapper .image-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#hire .find-and-hire-wrapper .image-wrapper {
        width: 100%
    }
      }
#hire .find-and-hire-wrapper .image-wrapper img {
        width: 100%;
      }
#hire .find-and-hire-wrapper .content-wrapper {
      width: calc(50% - 20px);
    }
@media (max-width: 960px) {
#hire .find-and-hire-wrapper .content-wrapper {
        width: 100%;
        margin-top: 30px
    }
      }
#hire .find-and-hire-wrapper .content-wrapper h2 {
        font-size: 30px;
        color: #16133e;
        margin-bottom: 40px;
        line-height: 1.3;
      }
@media (max-width: 960px) {
#hire .find-and-hire-wrapper .content-wrapper h2 {
          font-size: 25px;
          text-align: center
      }
        }
#hire .find-and-hire-wrapper .content-wrapper p {
        font-size: 22px;
        line-height: 1.3;
        color: #5a7184;
      }
@media (max-width: 960px) {
#hire .find-and-hire-wrapper .content-wrapper p {
          font-size: 16px;
          text-align: center
      }
        }
.info-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 38px;
}
@media (max-width: 960px) {
.info-cards {
    grid-template-columns: inherit
}
  }
.info-cards .info-card {
    display: flex;
    padding: 40px;
    border: 1px solid #e5eaf4;
    border-radius: 8px;
  }
.info-cards .info-card-title {
      color: #16133e;
      font-size: 23px;
      margin: 18px 0;
    }
.info-cards .info-card-description {
      color: #b1b1b1;
      font-size: 14px;
      line-height: 1.5;
    }
.info-cards .info-card-author {
      color: #454f63;
      font-size: 18px;
    }
.info-cards .info-card-column {
      text-align: left;
      flex-direction: column;
    }
.info-cards .info-card-column .info-card-description {
        color: #241239;
      }
.info-cards .info-card-column img {
        width: 200px;
        height: 200px;
      }
.info-cards .info-card-flex {
      align-items: center;
      text-align: left;
      gap: 20px;
    }
.info-cards .info-card-flex img {
        width: 100px;
        height: 100px;
      }
.info-cards .info-card-borderless {
      border: none;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      text-align: center;
    }
.info-cards .info-card-borderless h3 {
        color: #000000;
        font-size: 18px;
      }
.info-cards .info-card-borderless img {
        width: 200px;
      }
.info-cards .info-card-featuredImage {
      flex-direction: column;
      padding: 0;
      overflow: hidden;
      text-align: left;
    }
.info-cards .info-card-featuredImage div {
        padding: 20px;
      }
.info-cards .info-card-featuredImage div p {
          color: #b1b1b1;
        }
.info-cards .info-card-featuredImage img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
.download-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 38px;
  margin-top: 120px;
  padding: 30px 30px 0 30px;
  background-color: #f5f5f5;
  border-radius: 12px;
  align-items: center;
}
@media (max-width: 960px) {
.download-card {
    grid-template-columns: inherit
}
  }
.download-card .first-row {
    padding: 50px;
  }
@media (max-width: 960px) {
.download-card .first-row {
      padding: 10px
  }
    }
.download-card .first-row h2 {
      font-size: 38px;
      color: #16133e;
    }
@media (max-width: 960px) {
.download-card .first-row h2 {
        text-align: center;
        font-size: 25px;
        word-break: break-word
    }
      }
.download-card .first-row p {
      line-height: 1.8;
      margin: 20px 0;
      color: #5a7184;
    }
@media (max-width: 960px) {
.download-card .first-row p {
        word-break: break-word;
        text-align: center
    }
      }
.download-card .first-row button {
      width: 300px;
    }
@media (max-width: 960px) {
.download-card .first-row button {
        width: 100%
    }
      }
.download-card .second-row img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
.business-testimonial-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 38px;
  margin-top: 120px;
  padding: 30px 30px 0 30px;
  border-radius: 12px;
  align-items: center;
}
@media (max-width: 500px) {
.business-testimonial-cards {
    grid-template-columns: inherit;
    padding: 15px 15px 0 15px
}
  }
.business-testimonial-cards .business-testimonial-card img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
.business-testimonial-cards div {
    padding: 20px;
  }
.business-testimonial-cards div h3 {
      font-size: 28px;
      color: #16133e;
      margin: 20px 0;
    }
@media (max-width: 960px) {
.business-testimonial-cards div h3 {
        font-size: 20px
    }
      }
.business-testimonial-cards div p {
      line-height: 1.8;
      margin: 20px 0;
      color: #5a7184;
    }
#select-role {
  margin: 100px;
}
@media (max-width: 960px) {
#select-role {
    margin-left: 0;
    margin-right: 0
}
  }
#testimonials {
  background-color: #46a38515;
  padding: 20px;
}
section {
  text-align: center;
}
section .page-section-heading {
    margin-top: 100px;
    margin-bottom: 60px;
  }
section .page-section-heading .main-title {
      color: #16133e;
      font-size: 38px;
    }
@media (max-width: 960px) {
section .page-section-heading .main-title {
        font-size: 25px
    }
      }
section .page-section-heading .main-text {
      color: #5a7184;
      font-size: 17px;
      padding-top: 13px;
    }
.road-map {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.road-map .road {
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    justify-content: space-around;
    align-items: center;
  }
@media (max-width: 960px) {
.road-map .road {
      grid-template-columns: inherit
  }
    }
.road-map .road .road-image img {
        width: 300px;
        height: 300px;
      }
.road-map .road .road-info {
      text-align: left;
      padding: 20px;
    }
.road-map .road .road-info h2 {
        color: #16133e;
        font-size: 38px;
        margin-bottom: 28px;
      }
@media (max-width: 960px) {
.road-map .road .road-info h2 {
          font-size: 25px
      }
        }
.road-map .road .road-info p {
        color: #5a7184;
        font-size: 17px;
        line-height: 1.5;
      }
.road-map > .road:nth-child(odd) .road-image {
      order: 2;
    }
.wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
.wrapper .loading {
    width: 100%;
    height: 100%;
    position: absolute;
  }
.wrapper .subscription_wrapper {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background-color: #fff;
    border-radius: 12px;
  }
@media (max-width: 500px) {
.wrapper .subscription_wrapper {
      padding: 25px
  }
    }
.wrapper .subscription_wrapper .main-title {
      color: #16133e;
      font-size: 38px;
    }
@media (max-width: 960px) {
.wrapper .subscription_wrapper .main-title {
        font-size: 25px
    }
      }
.wrapper .subscription_wrapper .main-text {
      color: #5a7184;
      font-size: 17px;
      padding-top: 13px;
    }
@media (max-width: 960px) {
.wrapper .subscription_wrapper .main-text {
        font-size: 16px
    }
      }
.wrapper .subscription_wrapper .sub-text {
      color: #5a7184;
      font-size: 14px;
      padding-top: 13px;
    }
.wrapper .subscription_wrapper .main-price {
      color: #16133e;
      font-size: 38px;
    }
.wrapper .subscription_wrapper .packageItem {
      display: flex;
      align-items: center;
    }
.wrapper .subscription_wrapper .packageItem svg {
        width: 100%;
        max-width: 26px;
        height: auto;
        color: #2bd49c;
      }
.wrapper .subscription_wrapper .packageItem p {
        padding-top: 14px;
        padding-left: 12px;
        color: #5a7184;
      }
.wrapper .subscription_wrapper .packageItem .underline {
        text-decoration: underline;
      }
.wrapper .subscription_wrapper .button-wrapper {
      width: 100%;
      margin-top: 60px;
    }
.wrapper .subscription_wrapper .btn-c {
      border-radius: 12px;
      width: 100%;
      padding: 16px 32px;
      background-color: #2bd49c;
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      border: 2px solid #2bd49c;
      transition: 0.3s ease-in-out;
    }
.wrapper .subscription_wrapper .btn-c:hover {
        border: 2px solid #2bd49c;
        background-color: transparent;
        color: #2bd49c;
      }
.content-box {
  padding: 15px 0;
  text-align: center;
}
.content-box .content-box__title {
    font-size: 40px;
    margin-bottom: 60px;
    font-weight: 600;
    position: relative;
    color: #78849e;
    text-transform: uppercase;
  }
.content-box .content-box__title::after {
      content: "";
      width: 126px;
      height: 12px;
      position: absolute;
      left: calc(50%);
      transform: translateX(-50%);
      bottom: -35px;
      background-color: #78849e;
    }
.content-box .content-box__children {
    margin-top: 45px;
  }
.content-box__center {
  text-align: center;
}
.content-box__center .content-box__title {
    margin: 0 auto 60px;
  }
.content-box__center .content-box__title::after {
      left: calc(50%);
      transform: translateX(-50%);
    }
.content-box__center .content-box__description {
    margin: 0 auto 60px;
    color: #78849e;
    font-size: 25px;
  }
.content-box__description.desc-gray {
  font-size: 25px;
  color: #78849e;
}
.content--box {
  padding: 15px 0;
  text-align: left;
}
.content--box .content-box-title {
    font-size: 40px;
    margin-bottom: 60px;
    font-weight: 600;
    position: relative;
    color: #78849e;
    text-transform: uppercase;
  }
.content--box .content-box-title::after {
      content: "";
      width: 126px;
      height: 12px;
      position: absolute;
      left: 0;
      bottom: -35px;
      background-color: #78849e;
    }
.content--box .content-box-children {
    margin-top: 45px;
  }
.content-box__center {
  text-align: center;
}
.content-box__center .content-box-title {
    margin: 0 auto 60px;
  }
.content-box__center .content-box-title::after {
      left: calc(50%);
      transform: translateX(-50%);
    }
.content-box__center .content-box-description {
    margin: 0 auto 60px;
    color: #78849e;
    font-size: 25px;
  }
.content-box-description.desc-gray {
  font-size: 25px;
  color: #78849e;
}
@media (max-width: 360px) {
  .content-box .content-box__title,
  .content-box-title {
    font-size: 30px !important;
    max-width: 100%;
  }
  .content-box-description,
  .content-box__description {
    font-size: 22px !important;
  }
}
.company-slider:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    background: linear-gradient(
      86deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 68%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 2;
  }
@media (max-width: 1024px) {
.company-slider:before {
      display: none
  }
    }
.company-slider:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 20%;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 68%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 2;
  }
@media (max-width: 1024px) {
.company-slider:after {
      display: none
  }
    }
.company-slider .alice-carousel__dots-item {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #000000;
  }
.company-slider .alice-carousel__dots-item:last-child {
      margin-right: 0;
    }
.company-slider .alice-carousel__dots-item.__active {
    background-color: #000000;
  }
.company-slider .carousel__next-btn {
    position: absolute;
    width: 20px;
  }
.company-slider .alice-carousel__prev-btn {
    position: absolute;
    width: 20px;
    left: 7%;
    top: 120px;
    z-index: 100;
    transform: scale(1.2, 2);
    transition: transform 0.15s linear;
  }
.company-slider .alice-carousel__prev-btn p {
      margin: 0;
    }
.company-slider .alice-carousel__prev-btn:hover {
      transform: scale(1.3, 2.4);
    }
@media (max-width: 1024px) {
.company-slider .alice-carousel__prev-btn {
      display: none
  }
    }
.company-slider .alice-carousel__prev-btn [data-area]::after {
    position: relative;
    content: "<";
    text-transform: capitalize;
    font-size: 50px;
    color: #707070;
  }
.company-slider .alice-carousel__next-btn {
    position: absolute;
    width: 20px;
    right: 7%;
    top: 120px;
    z-index: 100;
    transform: scale(1.3, 2);
    transition: transform 0.15s linear;
  }
.company-slider .alice-carousel__next-btn p {
      margin: 0;
    }
.company-slider .alice-carousel__next-btn:hover {
      transform: scale(1.3, 2.4);
    }
@media (max-width: 1024px) {
.company-slider .alice-carousel__next-btn {
      display: none
  }
    }
.company-slider .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: ">";
    text-transform: capitalize;
    font-size: 50px;
    color: #707070;
  }
.pricing-card {
  margin: 15px;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.pricing-card-hr {
  height: 1px;
  color: #0c0c0c;
  background-color: #0c0c0c;
  opacity: 0.2;
}
.pricing-card-content {
  width: 100%;
  padding: 30px;
  margin: 20px;
  background: #f0f0f0;
  border-radius: 8px;
  color: #78849e;
}
.pricing-card-content:nth-child(2) {
    background-color: #2bd49c;
    color: #fff;
    fill: #fff;
  }
.pricing-card-content:nth-child(2) svg#Layer_1 {
      fill: white;
    }
.pricing-card-content:nth-child(2) button {
      background: #f0f0f0;
      color: #78849e;
    }
.pricing-card-content:nth-child(2) button:hover {
        background-color: #e3e3e3;
        transition: 0.5s;
      }
.pricing-card-content h1 {
    font-size: 30px;
  }
.pricing-card-price-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pricing-card-price-content h1 {
    font-size: 40px;
    margin: 0;
  }
.pricing-card-price-content p {
    font-size: 20px;
    margin: 0 0 0 5px;
  }
.pricing-card-info-content {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.pricing-card-info-content span {
    width: 20px;
    height: 20px;
  }
.pricing-card-info-content p {
    margin: 0 0 0 20px;
  }
svg#Layer_1 {
  fill: #29d49c;
  width: 20px;
  height: 20px;
}
.pricing-card-button {
  margin-top: 30px;
}
.pricing-card-button button {
    border: 0;
    border-radius: 8px;
    padding: 10px 20px;
    background: #2bd49c;
    color: #fff;
  }
.pricing-card-button button:hover {
      background-color: #25bf8c;
      transition: 0.5s;
    }
@media (max-width: 768px) {
  .pricing-card {
    flex-direction: column;
    margin: 0 !important;
  }
  .pricing-card-content {
    margin: 20px 0 !important;
  }
}
#pagination{
    display: flex;
    justify-content: center;
    padding: 45px 15px 15px;
}
@media (max-width: 768px){
#pagination{
        padding: 30px 0
}
    }
#pagination .rc-pagination{
        border: 2px solid #DFDFDF;
        border-radius: 5px;
        padding: 10px 25px;
        display: flex;
        justify-content: center;
    }
@media (max-width: 768px){
#pagination .rc-pagination{
            padding: 15px
    }
        }
#pagination .rc-pagination .rc-pagination-item {
            padding: 5px 20px;
            height: 40px;
            border-radius: 0;
            border: none;
        }
#pagination .rc-pagination .rc-pagination-item a{
                color: #000000;
                font-size: 16px;
                outline: none;
            }
@media (max-width: 768px){
#pagination .rc-pagination .rc-pagination-item a{
                    font-size: 16px
            }
                }
@media (max-width: 768px){
#pagination .rc-pagination .rc-pagination-item {
                padding: 2px;
                height: 30px
        }
            }
#pagination .rc-pagination .rc-pagination-item-active{
            outline: none;
            background-color: #000000;
        }
#pagination .rc-pagination .rc-pagination-item-active a{
                color: #ffffff;
                outline: none;
            }
#pagination .rc-pagination .rc-pagination-next, #pagination .rc-pagination .rc-pagination-prev{
            border: none;
            outline: none;
            color: #000000;
        }
#pagination .rc-pagination .rc-pagination-next a:after {
            content: 'Next';
            display: block;
            padding-top: 5px;
            padding-left: 15px;
        }
@media (max-width: 768px){
#pagination .rc-pagination .rc-pagination-next a:after {
                padding-left: 5px;
                padding-top: 2px
        }
            }
#pagination .rc-pagination .rc-pagination-prev a:after {
            content: 'Prev';
            display: block;
            padding-top: 8px;
            padding-right: 15px;
        }
@media (max-width: 768px){
#pagination .rc-pagination .rc-pagination-prev a:after {
                padding-right: 5px;
                padding-top: 2px
        }
            }
.news-box{
    padding: 15px;
    text-align: center;
}
.news-box .news-box__image{
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
.news-box .news-box__date{
        color: #9E9D9D;
        font-size: 18px;
        margin-top: 25px;
        font-weight: 500;
    }
.news-box .news-box__title{
        font-size: 22px;
        font-weight: 700;
    }
.looking-card-wrapper {
  padding: 15px;
}
.looking-card-wrapper .looking-card {
    border: 1px solid rgba(120, 132, 158, 0.555);
    padding: 45px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.15s linear;
    border-radius: 8px;
  }
.looking-card-wrapper .looking-card img {
      margin-right: 30px;
      height: 80px;
    }
.looking-card-wrapper .looking-card .looking-card__data .looking-card__title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 5px;
        color: #78849e;
      }
.looking-card-wrapper .looking-card .looking-card__data .looking-card__description {
        font-size: 18px;
        color: #9e9d9d;
      }
.looking-card-wrapper .looking-card:hover {
      background-color: #f7f7f7;
      transition: 0.5s;
    }
@media (max-width: 500px) {
  .looking-card {
    padding: 40px 30px !important;
  }
}
@media (max-width: 360px) {
  .looking-card {
    flex-direction: column !important;
    padding: 30px !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
  }

    .looking-card img {
      margin: 0 0 20px 0 !important;
    }
  .looking-card__title {
    margin: 0 !important;
  }
}
.comment-slider .alice-carousel__dots-item {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #000000;
  }
.comment-slider .alice-carousel__dots-item:last-child {
      margin-right: 0;
    }
.comment-slider .alice-carousel__dots-item.__active {
    background-color: #000000;
  }
.comment-slider .carousel__next-btn {
    position: absolute;
    width: 20px;
  }
.comment-slider .alice-carousel__prev-btn {
    position: absolute;
    width: 20px;
    left: calc(50% - 120px);
    top: 15px;
    z-index: 10;
    transform: scale(1.3, 2);
    transition: transform 0.15s linear;
  }
.comment-slider .alice-carousel__prev-btn p {
      margin: 0;
    }
.comment-slider .alice-carousel__prev-btn:hover {
      transform: scale(1.3, 2.4);
    }
.comment-slider .alice-carousel__prev-btn [data-area]::after {
    position: relative;
    content: "<";
    text-transform: capitalize;
    font-size: 50px;
    color: #707070;
  }
.comment-slider .alice-carousel__next-btn {
    position: absolute;
    width: 0px;
    right: calc(50% - 90px);
    top: 15px;
    z-index: 10;
    transform: scale(1.3, 2);
    transition: transform 0.15s linear;
  }
.comment-slider .alice-carousel__next-btn p {
      margin: 0;
    }
.comment-slider .alice-carousel__next-btn:hover {
      transform: scale(1.3, 2.4);
    }
.comment-slider .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: ">";
    text-transform: capitalize;
    font-size: 50px;
    color: #707070;
  }
.comment-slider .comment {
    text-align: center;
    padding: 15px;
  }
.comment-slider .comment .user-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-bottom: 15px;
      object-fit: cover;
    }
.comment-slider .comment .user-image svg {
        color: #2bd49c !important;
      }
.comment-slider .comment .user-comment {
      font-size: 18px;
      max-width: 600px;
      margin: 0 auto 15px;
    }
.comment-slider .comment i {
      font-size: 55px;
      color: #dfdfdf;
      margin-bottom: 15px;
    }
.comment-slider .comment .user-name {
      font-size: 20px;
      font-weight: 600;
    }
.comment-slider .comment .user-location {
      font-size: 18px;
      color: #9e9d9d;
    }
.crew-slider {
  max-width: 1200px;
}
/*
   border: 1px solid red;
   &:before {
     content: "";
     position: absolute;
     left: -3%;
     top: 0;
     height: 100%;
     width: 5%;
     background: linear-gradient(
       86deg,
       rgba(255, 255, 255, 1) 0%,
       rgba(255, 255, 255, 1) 68%,
       rgba(0, 0, 0, 0) 100%
     );
     z-index: 2;

     @media (max-width: 1024px) {
       display: none;
     }
   }

   &:after {
     content: "";
     position: absolute;
     right: -3%;
     top: 0;
     height: 100%;
     width: 5%;
     background: linear-gradient(
       270deg,
       rgba(255, 255, 255, 1) 0%,
       rgba(255, 255, 255, 1) 68%,
       rgba(0, 0, 0, 0) 100%
     );
     z-index: 2;

     @media (max-width: 1024px) {
       display: none;
     }
   }

  */
.crew-slider .alice-carousel__dots-item {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #000000;
    display: none;
  }
.crew-slider .alice-carousel__dots-item:last-child {
      margin-right: 0;
    }
.crew-slider .alice-carousel__dots-item.__active {
    background-color: #000000;
  }
.crew-slider .carousel__next-btn {
    position: absolute;
    width: 20px;
  }
.crew-slider .alice-carousel__prev-btn {
    position: absolute;
    width: 100px;
    left: -15%;
    top: 15px;
    z-index: 10;
    transform: scale(1.3, 2);
    transition: transform 0.15s linear;
  }
.crew-slider .alice-carousel__prev-btn p {
      margin: 0;
    }
@media (max-width: 1024px) {
.crew-slider .alice-carousel__prev-btn {
      display: none
  }
    }
.crew-slider .alice-carousel__prev-btn [data-area]::after {
    position: relative;
    content: "<";
    text-transform: capitalize;
    font-size: 50px;
    color: #707070;
  }
.crew-slider .alice-carousel__next-btn {
    position: absolute;
    width: 100px;
    right: -12%;
    top: 15px;
    z-index: 10;
    transform: scale(1.3, 2);
    transition: transform 0.15s linear;
  }
.crew-slider .alice-carousel__next-btn p {
      margin: 0;
    }
@media (max-width: 1450px) {
.crew-slider .alice-carousel__next-btn {
      display: none
  }
    }
.crew-slider .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: ">";
    text-transform: capitalize;
    font-size: 50px;
    color: #707070;
  }
.CompanyProfileBox__wrapper{
    padding: 15px;
}
.CompanyProfileBox__wrapper .profile-box{
        border: 1px solid #DFDFDF;
        border-radius: 2px;
        padding: 45px 15px 30px;
        text-align: center;

    }
.CompanyProfileBox__wrapper .profile-box .avatar{
            margin: 0 auto;
            width: 121px;
            height: 121px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
.CompanyProfileBox__wrapper .profile-box .avatar svg{
                font-size: 40px;
                border-radius: 50%;
                object-fit: cover;
                margin: 15px;
                color: #000000;
            }
.CompanyProfileBox__wrapper .profile-box .profile-box__image{
            width: 121px;
            height: 121px;
            border-radius: 50%;
            object-fit: cover;
        }
.CompanyProfileBox__wrapper .profile-box .profile-box__name{
            margin-top: 15px;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 5px;
        }
.CompanyProfileBox__wrapper .profile-box .profile-box__data{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 5px;
            padding-bottom: 15px;
            
        }
.CompanyProfileBox__wrapper .profile-box .profile-box__data .box{
                display: flex;
                align-items: center;
                color: #9E9D9D;
                margin-bottom: 5px;
            }
.CompanyProfileBox__wrapper .profile-box .profile-box__data .box svg{
                    margin-right: 5px;
                    width: 20px;
                }
.CompanyProfileBox__wrapper .profile-box .btn-see-profile{
            color: #000000;
            font-weight: 600;
            text-decoration: underline;
        }
@media (max-width: 992px){
.CompanyProfileBox__wrapper{
        padding: 0
}
    }
.banner {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.banner .banner__image {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    object-fit: cover;
  }
.banner .banner__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 100%;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0),
      #24bc89,
      #24bc89
    );
    z-index: 1;
  }
@media (max-width: 600px) {
.banner .banner__overlay {
      width: 90%
  }
    }
.banner .banner__info {
    max-width: 650px;
    position: absolute;
    top: 45%;
    left: 35%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: #fff;
  }
@media (max-width: 960px) {
.banner .banner__info {
      left: 15px;
      transform: translate(0, -50%);
      padding-right: 20px
  }
    }
.banner .banner__info h1 {
      font-size: 74px;
      font-weight: bold;
      line-height: 1.1;
    }
@media (max-width: 960px) {
.banner .banner__info h1 {
        font-size: 30px
    }
      }
.banner .banner__info p {
      font-size: 14px;
      padding-top: 24px;
      max-width: 400px;
      line-height: 1.5;
    }
.become-worqer .banner__image {
    max-width: 449px;
  }
.secondary-background {
  background-color: #caebe0;
  max-height: 450px;
}
.secondary-background .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
  }
@media (max-width: 960px) {
.secondary-background .container {
      justify-content: center
  }
    }
.secondary-background .banner__image {
    padding-top: 60px;
    width: 100%;
  }
@media (max-width: 960px) {
.secondary-background .banner__image {
      max-width: 200px;
      width: 100%;
      padding-top: 20px
  }
    }
@media (max-width: 960px) {
.secondary-background {
    padding-top: 30px;
    margin-top: 25px
}
  }
.secondary-background h1 {
    font-size: 74px;
    font-weight: bold;
    line-height: 1.1;
  }
@media (max-width: 960px) {
.secondary-background h1 {
      font-size: 30px;
      text-align: center
  }
    }
.secondary-background p {
    font-size: 14px;
    padding-top: 24px;
    max-width: 400px;
    line-height: 1.5;
    text-align: left;
  }
@media (max-width: 960px) {
.secondary-background p {
      text-align: center
  }
    }
.secondary-background img {
    width: 100%;
    max-width: 280px;
  }
@media (max-width: 960px) {
.secondary-background img {
      max-width: 300px
  }
    }
@media (max-width: 960px) {
.explore-card__cardWrapper {
    grid-template-columns: inherit
}
  }
.app__layout {
  position: relative;
  display: grid;
  grid-template-columns: 100px 200px 1fr;
  grid-template-rows: 90px 1fr 10px;

  max-width: 100%;
  height: 100vh;
}
.app__layout .app__logo {
    grid-row: 1 / 2;
    grid-column: 1 / 3;

    width: 324px;
    background-color: #241239;
    padding: 23px 0 23px 50px;
  }
.app__layout .app__logo__link {
      cursor: pointer;
    }
.app__layout .app__logo img {
      width: 137px;
      height: 45px;
    }
.app__layout .app__sideBar {
    grid-row: 2 / 4;
    grid-column: 1 / 3;

    background-color: #fff;
    padding: 64px 0 0 50px;
  }
.app__layout .app__mainContent {
    overflow-y: scroll;

    grid-row: 1 / 4;
    grid-column: 3 / 4;

    background-color: #f7f7fa;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 21px 0px 0px 0px;
    padding: 42px 53px 53px 52.8px;
  }
.app__layout .app__mainContent__fullWidth {
      grid-column: 1 / 4;
    }
.app__layout .menu__button {
    display: none;
    color: #222;
    font-size: 30px;
    position: absolute;
    bottom: 10px;
    left: 13px;
    z-index: 999;
  }
.col-2,
.col-10 {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 1024px) {
    .app__layout .app__logo {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
      padding: 1.2rem 1.5rem;
      width: 120px;
    }

      .app__layout .app__logo img {
        width: 50px;
        height: 50px;
      }

    .app__layout .app__sideBar {
      grid-row: 2 / 4;
      grid-column: 1 / 2;
      padding: 0;
    }

    .app__layout .app__mainContent {
      grid-row: 1 / 4;
      grid-column: 2 / 4;

      box-shadow: 0px 3px 6px #00000029;
      border-radius: 0;
    }

      .app__layout .app__mainContent.app__mainContent__fullWidth {
        grid-column: 1 / 4;
      }
}
@media screen and (max-width: 640px) {
    .app__layout .app__logo {
      width: 100px;
    }
    .app__layout .app__mainContent {
      grid-column: 1 / 4;
    }

    .app__layout .app__logo,
    .app__layout .app__sideBar {
      z-index: 999;
      background: #ddd;
    }

    .app__layout .menu__button {
      display: block;
    }
}
@media screen and (max-width: 456px) {
    .app__layout .app__mainContent {
      padding: 20px;
    }
}
.auth_layout {
  display: flex;
  flex-flow: column;
  padding: 0px;
  width: 100%;
  min-height: 100vh;
}
